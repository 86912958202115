import { CardContent, CardHalf, CardHeader, Container, CustomButton, FixWidthContentWrapper, GrayPage } from "@app/styles";
import Card from "react-bootstrap/Card";
import Carousel from 'react-bootstrap/Carousel';
import { LightBlubIcon, ScopeIcon, BrainIcon } from "@app/styles/icons";
import { FocusCard, FocusCardContainer } from "@app/components/landing/styles";
import { ProposalList } from "@app/components/focus-chat/proposal-history";
import { FocusChatList } from "@app/components/focus-chat/list";
import { useAppDispatchContext, useAppContext } from "@app/app/appContext";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { queryProposalList } from "@app/app/queries";
import { setCurrentProposal } from "@app/app/actions";
import { EDIT_MODE } from "../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie, faUserLarge, faBarsProgress } from "@fortawesome/free-solid-svg-icons";
import { Button } from "devextreme-react";
import { defaultFormat, userTimeZone } from "@app/utils";
import { HoverButton } from "@app/styles";

const Home = () => {
  const dispatch = useAppDispatchContext();
  const navigate = useNavigate();
  const chat = useAppContext();
  const [recentProposals, setRecentProposals] = useState([]);
  const [allProposals, setAllProposals] = useState([]);
  const { isLoading, error, data, refetch } = queryProposalList();
  const oneMonthAgo = moment().subtract(1, 'months').unix();
  const [visibleCards, setVisibleCards] = useState(4);

  const updateVisibleCards = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 1190) {
      setVisibleCards(4);
    } else if (screenWidth >= 932) {
      setVisibleCards(3);
    } else {
      setVisibleCards(1);
    }
  };

  useEffect(() => {
    updateVisibleCards();
    window.addEventListener('resize', updateVisibleCards);
    return () => window.removeEventListener('resize', updateVisibleCards);
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (data) {
      setAllProposals(data);

      const filteredData = data
        .filter(i => parseInt(i.updated) > oneMonthAgo)
        .sort((a, b) => b.updated - a.updated)
        .slice(0, 4)
        .sort((a, b) => a.proposal_name.localeCompare(b.proposal_name));

      setRecentProposals(filteredData);
    }
  }, [data]);

  const editProposal = (data) => {
    const { id, status } = data;
    dispatch(setCurrentProposal({ id, status }));
    navigate(`/proposal/${id}/${status}`, { state: { mode: EDIT_MODE.EDIT } });
  };

  return (
    <GrayPage>
      <FixWidthContentWrapper paddingBottom="80px" className="pt-3">
        <Container style={{ backgroundColor: 'white' }}>
          <CardHalf>
            <CardHeader style={{ backgroundColor: "#e6b712" }}>Recently Updated</CardHeader>
            <CardContent style={{ backgroundColor: '#007fc5', position: 'relative', overflowY: 'auto' }}>
              {visibleCards === 1 ? (
                <div className="d-flex flex-row justify-content-center">
                  <Carousel
                    interval={null}
                    indicators={false}
                    prevIcon={
                      <span aria-hidden="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="black" viewBox="0 0 8 8">
                          <path d="M5.25 0L6.75 1.5 4.25 4l2.5 2.5-1.5 1.5L1.25 4l4-4z" />
                        </svg>
                      </span>
                    }
                    nextIcon={
                      <span aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="black" viewBox="0 0 8 8">
                          <path d="M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z" />
                        </svg>
                      </span>
                    }
                    className="responsive-carousel"
                  >
                    {recentProposals.map((i, index) => (
                      <Carousel.Item key={index}>
                        <div
                          data-timestamp-id={i.timestamp_id}
                          onClick={() => editProposal(i)}
                          style={{
                            marginBottom: '1.5rem',
                            marginRight: '1.5rem',
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <Card
                            className="border-0"
                            style={{
                              borderRadius: '2rem',
                              borderBlockColor: 'black',
                              boxShadow: '20px 20px 10px 0px rgba(0, 0, 0, 0.25)',
                              cursor: 'pointer',
                              width: '250px',
                            }}
                          >
                            <div
                              style={{
                                borderTopRightRadius: '2rem',
                                borderTopLeftRadius: '2rem',
                                overflow: 'hidden',
                              }}
                            >
                              <Card.Img
                                variant="top"
                                src="https://placehold.co/200x35"
                                alt="Placeholder image representing a user dashboard"
                              />
                            </div>
                            <Card.Body className="pt-2 d-flex flex-column justify-content-start">
                              <div>
                                <h6 className="font-weight-bold mb-1">{i.proposal_name}</h6>
                              </div>
                              <div className="detail w-100">
                                <div className="timestamp text-muted justify-content-center" style={{ fontSize: '12px' }}>
                                  {i.updated &&
                                    moment
                                      .unix(parseInt(i.updated))
                                      .tz(userTimeZone)
                                      .format(defaultFormat)}
                                </div>
                              </div>
                              <HoverButton className="mt-1 text-uppercase">Edit</HoverButton>
                            </Card.Body>
                          </Card>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
                ) : (
                  <div className="d-flex flex-wrap">
                    {recentProposals.slice(0, visibleCards).map((i, index) => (
                      <div
                        key={index}
                        data-timestamp-id={i.timestamp_id}
                        onClick={() => editProposal(i)}
                        style={{
                          marginBottom: '1.5rem',
                          marginRight: '1.5rem',
                          display: 'flex',
                          justifyContent: 'center',
                          width: '250px',
                        }}
                      >
                        <Card
                          className="border-0"
                          style={{
                            borderRadius: '2rem',
                            borderBlockColor: 'black',
                            boxShadow: '20px 20px 10px 0px rgba(0, 0, 0, 0.25)',
                            cursor: 'pointer',
                            width: '250px',
                          }}
                        >
                          <div
                            style={{
                              borderTopRightRadius: '2rem',
                              borderTopLeftRadius: '2rem',
                              overflow: 'hidden',
                            }}
                          >
                            <Card.Img
                              variant="top"
                              src="https://placehold.co/200x35"
                              alt="Placeholder image representing a user dashboard"
                            />
                          </div>
                          <Card.Body className="pt-2 d-flex flex-column justify-content-start">
                            <div>
                              <h6 className="font-weight-bold mb-1">{i.proposal_name}</h6>
                            </div>
                            <div className="detail w-100">
                              <div className="timestamp text-muted justify-content-center" style={{ fontSize: '12px' }}>
                                {i.updated &&
                                  moment
                                    .unix(parseInt(i.updated))
                                    .tz(userTimeZone)
                                    .format(defaultFormat)}
                              </div>
                            </div>
                            <HoverButton className="mt-1 text-uppercase">Edit</HoverButton>
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </div>
                )}
              <div className="d-flex justify-content-center">
                <h1 className="text-white" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '-1' }}>
                  Welcome to the Proposal Generator.
                </h1>
              </div>
            </CardContent>
          </CardHalf>
        </Container>
        <Card className="mt-3" style={{ backgroundColor: "#004987" }}>
          {/* <FocusCard onClick={goNewChat}>
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                +
              </Card.Body>
            </FocusCard> */}
          <ProposalList />
        </Card>
      </FixWidthContentWrapper>
    </GrayPage>
  );
};
export default Home;
