import { useReducer } from "react";
import { chatReducer, initialState } from "@app/app/chatReducer";
import { AppContext, AppDispatchContext } from "@app/app/appContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import "react-quill/dist/quill.snow.css";
import Theme from "@app/styles/theme";
import { GlobalStyle } from "./styles/globalStyles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import KChatApp from "@app/app/kchat";
import ProposalApp from "@app/app/proposal";
import { msalConfig } from "@app/app/auth/config";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import Login from "@app/components/login";
import CheckLanding from "@app/components/landing/check-landing";

const queryClient = new QueryClient();
type props = {
  name: string;
};
const pca = new PublicClientApplication(msalConfig["chatMsalConfig"]);
const pca2 = new PublicClientApplication(msalConfig["proposalMsalConfig"]);

const KChat = (props: props) => {
  const [chat, dispatch] = useReducer(chatReducer, initialState);
  const { currentChat } = chat;
  const id = currentChat?.id;
  return (
    <AppContext.Provider value={chat}>
      <AppDispatchContext.Provider value={dispatch}>
        <QueryClientProvider client={queryClient}>
          <Theme>
            <GlobalStyle $whiteColor />
            <Router>
              <Routes>
                <Route
                  path="/kchat/*"
                  element={
                    <MsalProvider instance={pca}>
                      <>
                        <AuthenticatedTemplate>
                          <KChatApp />
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                          <Login loginType={"chat"} />
                        </UnauthenticatedTemplate>
                      </>
                    </MsalProvider>
                  }
                />
                <Route
                  path="/proposal/*"
                  element={
                    <MsalProvider instance={pca2}>
                      <>
                        <AuthenticatedTemplate>
                          <ProposalApp />
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                          <Login loginType={"proposal"} />
                        </UnauthenticatedTemplate>
                      </>
                    </MsalProvider>
                  }
                />

                <Route path="/" element={<CheckLanding />} />
              </Routes>
            </Router>
          </Theme>
        </QueryClientProvider>
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};
export default KChat;
