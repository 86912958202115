import { useAcquireToken } from "@app/app/auth/useAcquireToken";
import { ContentLoaderWrapper } from "@app/styles";
import { LoadIndicator } from "devextreme-react/load-indicator";

type LoginType = {
  loginType: string;
};

const LoginPage = (props: LoginType) => {
  const [requireToken] = useAcquireToken(props.loginType);
  return (
    <ContentLoaderWrapper id="large-indicator">
      <LoadIndicator />
    </ContentLoaderWrapper>
  );
};
export default LoginPage;
