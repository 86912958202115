import { useEffect, useState } from "react";
import { LoadIndicator } from "devextreme-react/load-indicator";

import { FocusCard, FocusCardContainer } from "@app/components/landing/styles";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserTie,
  faUserLarge,
  faBarsProgress,
  faGrip,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { OverflowEllipsisText, HistoryWrapper } from "./styles";
import moment from "moment-timezone";
import { defaultFormat, userTimeZone } from "@app/utils";
import { queryProposalList } from "@app/app/queries";
import { setCurrentProposal } from "@app/app/actions";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import { useNavigate } from "react-router-dom";
import { EDIT_MODE } from "@app/components/proposal/types";
import { ButtonGroup, ButtonGroupTypes } from "devextreme-react/button-group";

import { DataGrid, Column, SearchPanel } from "devextreme-react/data-grid";
import { PROPOSAL_STATUS } from "@app/components/proposal/types";
import { Button } from "devextreme-react/button";
import { CustomToggle, CustomButton, HoverButton, StyledInput, ResponsiveDiv } from "@app/styles";

export const ProposalList = () => {
  const dispatch = useAppDispatchContext();
  const [showList, setShowList] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  // const { isLoading, error, data } = useQuery({
  //   queryKey: ["chatList"],
  //   queryFn: () => apiConfig[CHAT_API.ENDPOINT_LIST_CHAT](),
  // });

  const { isLoading, error, data, refetch } = queryProposalList();

  useEffect(() => {
    refetch();
  }, []);

  const loadChatMessages = (e: React.MouseEvent<HTMLLIElement>) => {
    // const { timestampId } = e.currentTarget.dataset;
    // dispatch(setCurrentChatId({ id: timestampId }));
    // dispatch(setChatQuery({ query: null }));
    // dispatch(
    //   setCurrentSelectedDataSources({ focus: chat.currentFocusChatConfig })
    // );
    // navigate(`/kchat/chat/${timestampId}?support-focus-chat=true`); // TODO We should set constants for the routes
  };

  const editProposal = (data) => {
    const { id, status } = data;

    dispatch(setCurrentProposal({ id, status }));
    navigate(`/proposal/${id}/${status}`, { state: { mode: EDIT_MODE.EDIT } });
  };
  enum ButtonType {
    Success = "success",
  };
  const alignments = [
    {
      icon: "contentlayout",
      text: "list",
      alignment: "left",
      hint: "Align left",
      type: ButtonType.Success,
    },
    {
      icon: "smalliconslayout",
      text: "tile",
      alignment: "right",
      hint: "Align left",
      type: ButtonType.Success,
    },
  ];

  const itemClick = (e: ButtonGroupTypes.ItemClickEvent) => {
    const type = e.itemData.text;
    setShowList(type == "list");
  };

  const selectedItemKeys = ["right"];

  const dataEntryRow = (e) => {
    if (e.rowType == "data" && e.rowIndex % 2 === 0) {
      e.rowElement.style.backgroundColor = "#f5f5f5";
    }
  };

  const newProposal = () => {
    dispatch(setCurrentProposal({ id: null, status: null }));
    navigate("/proposal/edit");
  };

  const proposalActions = (data) => {
    const { status } = data.data;
    if (Object.values(PROPOSAL_STATUS).includes(status)) {
      return <Button icon={"rename"} onClick={() => editProposal(data.data)} />;
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data?.filter((i) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      i.proposal_name.toLowerCase().includes(searchLower) ||
      i.opportunity_number?.toString().toLowerCase().includes(searchLower) ||
      i.proposal_manager?.toLowerCase().includes(searchLower) ||
      i.capture_manager?.toLowerCase().includes(searchLower) ||
      i.status?.toLowerCase().includes(searchLower)
    );
  });

  const sortedFilteredData = filteredData
  ?.slice()
  .sort((a, b) => {
    const nameComparison = a.proposal_name.localeCompare(b.proposal_name);
    if (nameComparison !== 0) {
      return nameComparison; 
    }
    return a.created - b.created;
  });

  return (
    <>
      <div className="d-flex align-items-center ml-4 mr-2 mx-sm-5 my-3 justify-content-between">
        <div className="d-flex align-items-center flex-column flex-lg-row">
          <h4 className="h4 mr-1 mr-lg-5 ml-2 mb-0 text-white font-weight-bold py-3 py-lg-0">RFP History</h4>
          <CustomButton stylingMode="outlined" type="success" onClick={newProposal}>
            <FontAwesomeIcon icon={faPlus} className="mr-2 plus" />
            Start New
          </CustomButton>
        </div>
        <div className="d-flex flex-md-row justify-content-between flex-column">
          <StyledInput
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <CustomToggle
            items={alignments}
            keyExpr="alignment"
            stylingMode="outlined"
            defaultSelectedItemKeys={selectedItemKeys}
            onItemClick={itemClick}
            className="plus"
          />
        </div>
      </div>
      <HistoryWrapper className="mx-5 mt-2" style={{ borderRadius: '4px'}}>
        {showList ? (
          <DataGrid
            id="dataGrid"
            dataSource={sortedFilteredData}
            keyExpr="id"
            showBorders={true}
            allowColumnReordering={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            onRowPrepared={dataEntryRow}
            height="100%"
            className="table-header-bold search-left"
            
          >
            <Column dataField="id" caption="Proposal Id" />
            <Column dataField="proposal_name" caption="Proposal Name" />
            <Column
              dataField="opportunity_number"
              caption="Opportunity Number"
            />
            <Column dataField="proposal_manager" caption="Proposal Manager" />
            <Column dataField="capture_manager" caption="Capture Manager" />
            <Column dataField="status" caption="Status" />
            <Column
              dataField="created"
              caption="Created At"
              calculateDisplayValue={(data) => {
                return (
                  data.created &&
                  moment
                    .unix(parseInt(data.created))
                    .tz(userTimeZone)
                    .format(defaultFormat)
                );
              }}
            />
            <Column
              dataField="updated"
              caption="Updated At"
              calculateDisplayValue={(data) => {
                return (
                  data.created &&
                  moment
                    .unix(parseInt(data.updated))
                    .tz(userTimeZone)
                    .format(defaultFormat)
                );
              }}
            />
            <Column
              caption="Action"
              fixed={true}
              fix-position={"right"}
              allowFixing={true}
              cellRender={proposalActions}
            />
          </DataGrid>
        ) : (
          <FocusCardContainer className="d-flex">
            {isLoading && (
              <FocusCard className={`d-flex align-items-center`}>
                <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                  <LoadIndicator />
                </Card.Body>
              </FocusCard>
            )}
            {!error &&
              !isLoading &&
              Array.isArray(data) &&
              sortedFilteredData?.map((i, index) => (
                <ResponsiveDiv
                  key={index}
                  data-timestamp-id={i.timestamp_id}
                  onClick={() => editProposal(i)}
                  style={{ marginBottom: '1rem', marginRight: '1rem' }}
                >
                  <Card className="border-0 border-black" style={{ borderRadius: '2rem', boxShadow: '20px 20px 10px 0px rgba(0, 0, 0, 0.25)', cursor: 'pointer'
                  }}>
                    <div style={{ borderTopRightRadius: '2rem', borderTopLeftRadius: '2rem', overflow: 'hidden' }}>
                      <Card.Img
                        variant="top"
                        src="https://placehold.co/200x60"
                        alt="Placeholder image representing a user dashboard"
                      />
                    </div>
                    <Card.Body className="pt-2 d-flex flex-column justify-content-between">
                      <div>
                        <h6 className="font-weight-bold mb-2">{i.proposal_name}</h6>
                      </div>
                      <div className="w-sm-100">
                        <div style={{fontSize: '12px'}}>
                          <FontAwesomeIcon icon={faUserTie} />
                          <span className="ml-2">{i.capture_manager}</span>
                        </div>
                        <div style={{fontSize: '12px'}}>
                          <FontAwesomeIcon icon={faUserLarge} />
                          <span className="ml-2">{i.proposal_manager}</span>
                        </div>
                        <div style={{fontSize: '12px'}}>
                          <FontAwesomeIcon icon={faBarsProgress} />
                          <span className="ml-2">{i.status}</span>
                        </div>
                        <div className="h6 timestamp mt-1 text-muted justify-content-center" style={{fontSize: '12px'}}>
                          {i.updated &&
                            moment
                              .unix(parseInt(i.updated))
                              .tz(userTimeZone)
                              .format(defaultFormat)}
                        </div>
                      </div>
                      <HoverButton
                        className="mt-2 text-uppercase"
                      >
                        Edit
                      </HoverButton>
                    </Card.Body>
                  </Card>
                </ResponsiveDiv>
              ))}
          </FocusCardContainer>
        )}
      </HistoryWrapper>
    </>
  );
};
