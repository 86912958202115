import React, { useEffect, useState, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { subscribeToSignalr, websocketActionMap } from "./signalr-tools";
import { EDIT_MODE } from "../proposal/types";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import { setSignalRState } from "@app/app/actions";

// Define the type for the options parameter
type SubscriptionProps = {
    groupName?: string | null;
    subscriptionHandler?: (dispatch: any) => void;
    mode?: string;
};


const defaultOptions = {
    groupName: null,
    subscriptionHandler: websocketActionMap,
    mode: EDIT_MODE.NEW,
};

const withSignalR = (WrappedComponent, options: SubscriptionProps = defaultOptions) => {
    return (props) => {
        const { groupName, subscriptionHandler, mode } = { ...defaultOptions, ...options };
        const { instance } = useMsal();
        const state = useAppContext();
        const dispatch = useAppDispatchContext();
        const { signalRConnection, signalRInterval, subscriptionId } = state.signalR;
        
        useEffect(() => {
            console.log('SignalR subscriptionId',subscriptionId)
        },[subscriptionId])
        useEffect(() => {
            const cycleSignalR = async () => {
                if(signalRConnection === null && subscriptionId === null) { 
                    dispatch(setSignalRState({ signalRConnection:null, subscriptionId:'connect', signalRInterval: null }));
                }
                if (signalRConnection === null && subscriptionId === 'connect') {
                    console.log('SignalR Connection is null, subscribing...')
                    dispatch(setSignalRState({ subscriptionId:'connecting' }));
                    const {
                        connection: newConnection,
                        subscriptionHandle: subscription
                    }  = await subscribeToSignalr(instance, groupName);
                    
                    console.log('Subscribed to SignalR: ', subscription);
                    newConnection.on(subscription, () => subscriptionHandler(dispatch));
                    newConnection.start().catch(console.error);
                    newConnection.onclose(() => {
                        console.log('Connection closed');
                    })
                    console.log('Subscribed to SignalR successful')
                    dispatch(setSignalRState({ signalRConnection: newConnection, subscriptionId: subscription, signalRInterval: null }));
                    return
                }
                if (signalRInterval === null) {
                    console.log('Initializing SignalR 5 minute timer.')
                    const intervalId = setInterval(async () => {
                        // Refresh the token every 5 minutes
                        console.log('Closing SignalR connection due to timer expiration.');
                        if (signalRConnection !== null) {
                            signalRConnection.stop();
                        }
                        dispatch(setSignalRState({ signalRConnection: null, subscriptionId: null, signalRInterval: null }));
                    }, 300000); // 300000 ms = 5 minutes
                    dispatch(setSignalRState({ signalRConnection, subscriptionId, signalRInterval: intervalId }));
                }
                if (subscriptionId !== null && signalRConnection !== null) {
                    console.log('Reattaching client listener to SignalR subscription: ', subscriptionId);
                    signalRConnection.off(subscriptionId);
                    signalRConnection.on(subscriptionId, websocketActionMap(dispatch));
                    return 
                }
            }
            cycleSignalR();
        }, []);
      
        return <WrappedComponent {...props} subscriptionId={subscriptionId} />;
    };
};

export default withSignalR;
