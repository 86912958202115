import styled from "styled-components";
import { COLOR } from "@app/styles";
export const LeftPanel = styled.div`
  .showPanelButton {
    width: 40px;
    border: 1px solid ${COLOR.BORDER_GRAY};
    padding: 8px 12px;
    height: 44px;
    border-radius: 2px;
    cursor: pointer;
    position: fixed;
    background: white;
    margin: 5px;
  }
`;
export const ChatListWrapper = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "flex")} !important;
  width: 235px;
  max-width: 235px;
  background-color: ${COLOR.CORN_FLOWER_BLUE};
  height: 100%;
  > div:first-child {
    gap: 5px;
    > div {
      &:first-child {
      }
      &:second-child {
        width: 50px;
      }
      border: 1px solid ${COLOR.BORDER_GRAY};
      padding: 8px 12px;
      height: 44px;
      border-radius: 2px;
      cursor: pointer;
    }
  }
  ul {
    height: calc(100vh - 300px);
    overflow: auto;
    margin: 0;
    padding: 0;
  }
  li {
    height: 44px;
    width: 100%;
    list-style: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: ${COLOR.CORN_FLOWER_BLUE_LIGHTER};
    }
  }
`;
export const OneLineText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff;
  display: block;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;
export const ChatIcon = styled.img`
  width: 23px;
  height: 23px;
`;
export const MoreOptions = styled.div`
  height: 255px;
  border-top: 1px solid ${COLOR.BORDER_GRAY};
  padding: 10px 20px 15px;
  > div {
    height: 44px;
    cursor: pointer;
  }
`;
