//RFP
export const ENDPOINT_PROPOSAL_LIST = "proposal_list";
export const ENDPOINT_PROPOSAL_DOCUMENT = "proposal_document";
export const ENDPOINT_PROPOSAL_DOCUMENT_UPLOAD = "proposal_document_upload";
export const ENDPOINT_PROPOSAL_REQUIREMENTS = "proposal_requirements";
export const ENDPOINT_PROPOSAL_GET = "proposal_get";
export const ENDPOINT_PROPOSAL_POST = "proposal_post";
export const ENDPOINT_PROPOSAL_PUT = "proposal_put";
export const ENDPOINT_PROPOSAL = "proposal";
export const ENDPOINT_TEMPLATE = "template";
export const ENDPOINT_TEMPLATE_LIST = "template_list";
export const ENDPOINT_TEMPLATE_POST = "template_post";
export const ENDPOINT_TEMPLATE_GET = "template_get";
export const ENDPOINT_CHAT_PROPOSAL = "chat_proposal";
export const ENDPOINT_CHAT_PROPOSAL_POST = "chat_proposal_post";
export const ENDPOINT_CHAT_PROPOSAL_REQUEST_POST = "chat_proposal_request_post";
export const ENDPOINT_CHAT_WINTHEME_POST = "chat_wintheme_post";
export const ENDPOINT_CHAT_RESPONSE_POST = "chat_response_post";
export const ENDPOINT_CHAT_REQUIREMENT_POST = "chat_requirement_post";
export const ENDPOINT_PROPOSAL_LIST_DATASETS = "proposal_list_datasets";
export const ENDPOINT_PROPOSAL_LIST_FILES = "proposal_list_files"
export const ENDPOINT_RESPONSE_POST = "response_post";
export const ENDPOINT_PROPOSAL_STATUS_GET = "proposal_status_get";
export const ENDPOINT_FINALIZE_GET = "proposal_finalize_get";
export const ENDPOINT_REQUIREMENT_EXCLUDE = "propsoal_requirement_exclude"
export const ENDPOINT_REQUIREMENT_FEEDBACK = "propsoal_requirement_feedback"
export const ENDPOINT_LIST_ALLOWED_GROUPS = "list_allowed_groups";
export const ENDPOINT_SIGNALR_SUBSCRIBE = "signalr_subscribe";
export const ENDPOINT_PROPOSAL_FILE_PUT = "proposal_file_upload"
export const ENDPOINT_PROPOSAL_LIST_MEMBERS = "route_list_group_members"


// proposal_documents: [GET] http://localhost:7072/proposal/{proposal_id}/documents
// proposal_document_upload: [POST] http://localhost:7072/proposal/{proposal_id}/document/upload
// proposal_get: [GET] http://localhost:7072/proposal/{proposal_id}
// proposal_list: [GET] http://localhost:7072/proposal_list
// proposal_post: [POST] http://localhost:7072/proposal
// requirements: [GET] http://localhost:7072/proposal/{proposal_id}/requirements

