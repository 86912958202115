import React, { useEffect, useState, useRef } from "react";
import ReactQuill from "react-quill";
import { queryAllowedGroups, queryMembersList, queryProposal } from "@app/app/queries";
import { useChatWinTheme } from "@app/app/mutations";
import { ActionIconButton } from "@app/components/chat-content/styles";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import {
  faThumbsUp,
  faEdit,
  faCircleLeft,
  faCircleRight,
  faPaperPlane,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCircleExclamation,
  faPencil,
  faSpinner,
  faCommentDots
} from "@fortawesome/free-solid-svg-icons";
import Button from "devextreme-react/button";
import List from "devextreme-react/list";
import { Tooltip } from "devextreme-react/tooltip";
import styled from "styled-components";
import { scrollToBottom } from "@app/utils";
import { PlainButton } from "@app/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputWrapper, BottomArea } from "@app/components/chat-input/styles";
import { SummaryCard, Wrapper, MessageBoard, ChatQueryMessage } from "./styles";
import Avatar from "@app/components/avatar/user";
import AvatarAssisant from "@app/components/avatar/assistant";
import { MentionDropdownInverseCenter } from "../styles";
import { Logo } from "@app/styles/logo";

const avatars = [
  { id: 1, name: "KBRaiN", email: "kbrain@example.com" },
  { id: 2, name: "Avatar 1", email: "avatar1@example.com" },
  { id: 3, name: "Avatar 2", email: "avatar2@example.com" },
  { id: 4, name: "Avatar 3", email: "avatar3@example.com" },
];

const WinThemeReview = () => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const quillRef = React.createRef();
  const [value, setValue] = useState("");
  const dispatch = useAppDispatchContext();
  const state = useAppContext();
  const [editWinTheme, setEditWinTheme] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false);
  const [collaborate, setCollaborate] = useState<string | undefined>("");
  const [searchQuery, setSearchQuery] = useState('');
  const [latestPrompt, setLatestPrompt] = useState("");
  const {
    auth: { user },
  } = state;

  const {
    currentProposal: { id: currentProposalId, status: currentProposalStatus, isSendingWinThemeMessage, winThemeMessages, winTheme },
  } = state;
  const { isLoading, error, data, refetch } = queryProposal(currentProposalId);
  const chatWinTheme = useChatWinTheme(currentProposalId);
  const modules = {
    toolbar: false,
  };
  const { isLoading: groupsIsLoading, data: allowedGroups, refetch: groupsRefetch } = queryAllowedGroups();
  const [proposalGroup, setProposalGroup] = useState(null);
  const [groupMap, setGroupMap] = useState({});

  useEffect(() => {
    if (allowedGroups?.groups) {
      const newGroupMap = allowedGroups.groups.reduce((acc, group) => {
        acc[group.id] = group.entra_group;
        return acc;
      }, {});

      setGroupMap(newGroupMap);
      console.log(newGroupMap);

      setProposalGroup(allowedGroups.groups[0].entra_group);
    }
  }, [allowedGroups]);
  useEffect(() => {
    groupsRefetch();
  }, []);

  const { data: membersData, isLoading: membersLoading, error: membersError } = queryMembersList({
    group_id: proposalGroup,
    continuation_token: null
  });
  useEffect(() => {
    if (currentProposalId) {
      refetch();
    }
  }, []);

  useEffect(() => {
    scrollToBottom(messagesEndRef, { behavior: "smooth" });
  }, [isSendingWinThemeMessage]);

  const sendManualEdit = (modifiedText) => {
    chatWinTheme.mutate({
      proposal_id: currentProposalId,
      prompt: modifiedText
    });
    setEditWinTheme(false)
  }

  const cardContent = () => {
    const content = (winThemeMessages || data.winThemeMessages) || [{ "user": "KBRaiN", "content": `The generated Win Theme is: ${winTheme || data.winTheme}` }]

    return (
      <>
        {content.map((message, idx) => {
          return (
            <ChatQueryMessage key={idx} className="d-flex">
              <p className="query">
                {message.user === 'KBRaiN' ? (
                  <AvatarAssisant className="mr-3" />
                ) : (
                  <Avatar email={message.user} />
                )}
              </p>
              <div className="flex-grow-1">
                <p>{message.content}</p>
              </div>
            </ChatQueryMessage>
          );
        })}
        {(latestPrompt !== "" &&
          <ChatQueryMessage className="d-flex">
            <p className="query mr-3">
              <Avatar email={user?.username} />
            </p>
            <div className="flex-grow-1">
              {promptToDisplay}
            </div>
          </ChatQueryMessage>
        )}
        {(isSendingWinThemeMessage) && (
          <ChatQueryMessage className="d-flex">
            <p className="query">
              <AvatarAssisant className="mr-3" />
            </p>
            <div className="flex-grow-1">
              <ActionIconButton icon={faCommentDots} className="mt-1 blink" />
            </div>
          </ChatQueryMessage>
        )}
      </>
    );
  };

  const renderWinTheme = (data: any) => {

    if (winTheme || (data && data.winTheme)) {
      return (
        <SummaryCard className="card">
          <div className="card-body">
            <p className="d-flex w-100 flex-grow-1 justify-content-between  flex-column">
              <ActionIconButton icon={faPencil} onClick={(e) => setEditWinTheme(true)} />
              {!editWinTheme && <p className="card-text">{winTheme || data.winTheme}</p>}
              {editWinTheme &&
                <form onSubmit={(e) => { e.preventDefault(); sendManualEdit(`EDIT: ${e.target[0].value}`) }}>
                  <textarea
                    className='w-100 wintheme'
                    defaultValue={winTheme || data.winTheme}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    style={{ minHeight: '100px' }}
                  />
                  <div className="text-right"><Button useSubmitBehavior={true} className="mt-2">Save</Button></div>
                </form>
              }

            </p>
          </div>
        </SummaryCard>
      );
    } else {
      if (data?.status && data.status == "failed") {
        return (
          <p className="d-flex align-items-center">
            <span className="mr-3">{data.derivedFrom.pdfName}</span>
            <ActionIconButton icon={faCircleExclamation} />
          </p>
        );
      } else if (data?.status && data.status == "processing") {
        return (
          <p className="d-flex align-items-center">
            <span className="mr-3">{data.derivedFrom.pdfName}</span>
            <ActionIconButton icon={faSpinner} spin />
          </p>
        );
      } else {
        return (
          <p>
            {data?.derivedFrom?.pdfName || 'No capture documents processed. Please return to the "Documents" screen to upload a capture document to generate a Win Theme.'} - {data?.status || ''}
          </p>
        );
      }
    }
  };
  const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      e.stopPropagation();
      onSendMessage();
    }
  };
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    onSendMessage();
  };

  const onSendMessage = () => {
    //@ts-ignore
    const prompt = quillRef.current
      //@ts-ignore
      .getEditor()
      .getText()
      .replace(/(\r\n|\n|\r)/gm, "");

    setLatestPrompt(prompt);

    chatWinTheme.mutate({
      proposal_id: currentProposalId,
      prompt: prompt
    });
    setValue("");
  };

  const filteredAvatars = membersData?.members.filter(avatar =>
    avatar.displayName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelect = (avatar) => {
    //@ts-ignore
    const quill = quillRef.current.getEditor();

    if (!quill.hasFocus()) {
      quill.focus();
    }
    const cursorPosition = quill.getSelection().index;
    const mentionText = `@${avatar.name} `;
    const textBeforeCursor = quill.getText(0, cursorPosition);
    const lastAtSymbol = textBeforeCursor.lastIndexOf("@");
    quill.deleteText(lastAtSymbol, cursorPosition - lastAtSymbol);
    quill.clipboard.dangerouslyPasteHTML(lastAtSymbol, mentionText);
    quill.setSelection(lastAtSymbol + mentionText.length);
    setShowDropdown(false);
    setCollaborate(quill.root.innerHTML);
  };

  const onValueChanged = (content, delta, source, editor) => {
    setCollaborate(content);
    const cursorPosition = editor.getSelection().index;
    const textBeforeCursor = editor.getText(0, cursorPosition);
    const lastAtSymbol = textBeforeCursor.lastIndexOf("@");

    if (lastAtSymbol !== -1 && lastAtSymbol < cursorPosition) {
      setShowDropdown(true);
      setSearchQuery(textBeforeCursor.slice(lastAtSymbol + 1));
    } else {
      setShowDropdown(false);
    }if (lastAtSymbol !== -1 && lastAtSymbol < cursorPosition) {
      setShowDropdown(true);
      setSearchQuery(textBeforeCursor.slice(lastAtSymbol + 1));
    } else {
      setShowDropdown(false);
    }
  };

  const promptToDisplay = winThemeMessages?.[winThemeMessages.length - 1]?.content || latestPrompt || 'No messages available';

  return (
    <Wrapper key={1} className="d-flex justify-content-center">
      <div className="content">
        <form>
          <h4>Win Theme Review</h4>
          <List
            dataSource={[data]}
            height="calc( 100% - 300px )"
            itemRender={renderWinTheme}
          />
        </form>
      </div>
      <div>
        { (winTheme || (data && data.winTheme)) && <MessageBoard className="card">
          <div className="card-header">
            <h5>{`Collaborate on the Win Theme`}</h5>
          </div>
          <div className="card-body d-flex p-0">
            <div className="h-100 overflow-auto w-100">
              <div className="h-100 overflow-auto mt-3 mb-3">
                {data && cardContent()}
                <div ref={messagesEndRef}> </div>
              </div>
            </div>
          </div>

          <div className="card-text border-top pt-3">
            {showDropdown && (
              <MentionDropdownInverseCenter>
                {filteredAvatars && ( [
                  { id: 'unique-id-kbrain', displayName: "KBRaiN", photoUrl: null },
                  ...membersData.members.filter((avatar) => avatar.displayName !== "KBRaiN")
                ].map((avatar) => (
                  <div
                    key={avatar.id}
                    onClick={() => handleSelect(avatar)}
                    style={{ cursor: "pointer" }}
                    className="d-flex align-items-center p-3"
                  >
                    {avatar.displayName === "KBRaiN" ? (
                      <Logo />
                    ) : (
                      <Avatar email={avatar.photoUrl} />
                    )}
                    <span className="ml-1">{avatar.displayName !== null ? avatar.displayName : "Unknown"}</span>
                  </div>
                )))}
              </MentionDropdownInverseCenter>
            )}
            <InputWrapper
              className="d-flex align-items-center pt-3"
              hide={true}
              minW={true}
            >
              <ReactQuill
                //@ts-ignore
                ref={quillRef}
                modules={modules}
                formats={[]}
                value={collaborate}
                theme="snow"
                onChange={onValueChanged}
                onKeyDown={onKeydown}
                placeholder="Send a message..."
                className="inputbox flex-grow-1"
              />
              <PlainButton onClick={onClick}>
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  className={`cursor-pointer`}
                />
              </PlainButton>
            </InputWrapper>
          </div>
        </MessageBoard> }
      </div>
    </Wrapper>
  );
};

export default WinThemeReview;
