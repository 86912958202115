export const LightBlubIcon = () => <svg width="42" height="44" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_333_2048)">
<path d="M39.3311 16.4532C39.7091 16.4532 40.0191 16.7601 40.0191 17.1344C40.0191 17.5087 39.7091 17.8156 39.3311 17.8156C38.9531 17.8156 38.6431 17.5087 38.6431 17.1344C38.6431 16.7601 38.9531 16.4532 39.3311 16.4532Z" fill="#515151"/>
<path d="M36.8965 40.5342C37.2746 40.5342 37.5846 40.8411 37.5846 41.2154C37.5846 41.5897 37.2746 41.8966 36.8965 41.8966C36.5185 41.8966 36.2085 41.5897 36.2085 41.2154C36.2085 40.8411 36.5185 40.5342 36.8965 40.5342Z" fill="#1E81CE"/>
<path d="M34.5301 1.36237C34.9081 1.36237 35.2181 1.66927 35.2181 2.04355C35.2181 2.41783 34.9081 2.72473 34.5301 2.72473C34.152 2.72473 33.842 2.41783 33.842 2.04355C33.842 1.66927 34.152 1.36237 34.5301 1.36237Z" fill="#515151"/>
<path d="M14.6375 33.887C13.1102 33.887 11.893 32.6819 11.893 31.1698C11.893 28.752 10.9781 26.4913 9.45083 24.6798C7.16749 22.1123 5.71582 18.7213 5.71582 14.9411C5.71582 6.87172 12.4978 0.232046 20.6559 0.306901C28.7383 0.381757 35.2935 6.87172 35.2935 14.9486C35.2935 18.6465 33.925 22.0449 31.6341 24.6124C30.0312 26.424 29.1164 28.6846 29.1164 31.1024C29.1164 32.6145 27.8991 33.8197 26.3718 33.8197H25.7594C24.6177 33.8945 16.0816 33.8945 14.63 33.8945L14.6375 33.887Z" fill="white"/>
<path d="M33.615 31.0949C33.9931 31.0949 34.3031 31.4018 34.3031 31.7761C34.3031 32.1504 33.9931 32.4573 33.615 32.4573C33.237 32.4573 32.927 32.1504 32.927 31.7761C32.927 31.4018 33.237 31.0949 33.615 31.0949Z" fill="#515151"/>
<path d="M20.5047 33.887H15.7036V38.5655C15.7036 41.2828 17.9114 43.5434 20.7315 43.5434C23.5517 43.5434 25.7594 41.3576 25.7594 38.5655V33.887H20.4971H20.5047Z" fill="#007FC5"/>
<path d="M10.2146 35.3243C10.5927 35.3243 10.9027 35.6312 10.9027 36.0054C10.9027 36.3797 10.5927 36.6866 10.2146 36.6866C9.8366 36.6866 9.52661 36.3797 9.52661 36.0054C9.52661 35.6312 9.8366 35.3243 10.2146 35.3243Z" fill="#515151"/>
<path d="M3.58378 4.67847C3.96182 4.67847 4.27181 4.98537 4.27181 5.35965C4.27181 5.73393 3.96182 6.04083 3.58378 6.04083C3.20574 6.04083 2.89575 5.73393 2.89575 5.35965C2.89575 4.98537 3.20574 4.67847 3.58378 4.67847Z" fill="#515151"/>
<path d="M1.67851 23.018C2.05655 23.018 2.36654 23.3249 2.36654 23.6992C2.36654 24.0735 2.05655 24.3804 1.67851 24.3804C1.30047 24.3804 0.990479 24.0735 0.990479 23.6992C0.990479 23.3249 1.30047 23.018 1.67851 23.018Z" fill="#515151"/>
<path d="M26.3718 34.3437H25.7594C25.5326 34.3437 25.3058 34.1191 25.3058 33.887C25.3058 33.655 25.5326 33.4379 25.7594 33.4379H26.3718C27.5891 33.4379 28.5796 32.4573 28.5796 31.2521C28.5796 29.9721 28.8064 28.7594 29.2676 27.6291C29.7288 26.4988 30.3337 25.3685 31.1729 24.4627C33.4638 21.8952 34.7567 18.5791 34.7567 15.1058C34.7567 7.41068 28.4284 1.07043 20.6559 0.995577H20.5047C16.7697 0.995577 13.1859 2.4328 10.4413 5.15005C7.69677 7.8673 6.1695 11.4154 6.1695 15.1882C6.1695 18.6614 7.46239 21.9775 9.7533 24.5451C11.4318 26.4314 12.3466 28.8493 12.3466 31.3345C12.3466 31.9408 12.5735 32.4648 13.0347 32.9214C13.4883 33.378 14.0251 33.6026 14.6375 33.6026H15.7036C15.9304 33.6026 16.1648 33.8272 16.1648 34.0592C16.1648 34.2913 15.938 34.5083 15.7036 34.5083H14.6375C13.7983 34.5083 12.9591 34.2089 12.3542 33.6026C11.7418 32.9963 11.4393 32.2477 11.4393 31.342C11.4393 29.0813 10.6001 26.8881 9.07283 25.1514C6.6307 22.3593 5.26221 18.8112 5.26221 15.1133C5.26221 11.116 6.86509 7.26097 9.83646 4.39401C12.7322 1.51208 16.6185 -0.0748552 20.6559 1.45752e-07C24.6934 0.0748555 28.504 1.66179 31.3317 4.4539C34.1519 7.32086 35.7547 11.0936 35.7547 15.0983C35.7547 18.7962 34.3862 22.3443 31.9441 25.0616C31.1805 25.8925 30.5681 26.8731 30.19 27.9285C29.812 28.984 29.5776 30.1143 29.5776 31.2521C29.5776 32.9139 28.1259 34.3437 26.3794 34.3437H26.3718Z" fill="#515151"/>
<path d="M31.483 15.0983C31.2562 15.0983 31.0294 14.8738 31.0294 14.6492C31.0294 8.99013 26.3795 4.23681 20.588 4.23681C17.7678 4.23681 15.0989 5.29227 13.0423 7.25348C10.9858 9.21469 9.91973 11.8571 9.91973 14.6492C9.91973 14.8738 9.69291 15.0983 9.45853 15.0983C9.22415 15.0983 9.00488 14.8738 9.00488 14.6492C9.00488 11.6325 10.2222 8.76556 12.4375 6.5723C14.6452 4.38652 17.541 3.24872 20.6711 3.24872C23.8013 3.24872 26.5383 4.45389 28.7536 6.64715C30.8101 8.75808 32.0274 11.5502 32.0274 14.5743C32.0274 14.8738 31.8006 15.1058 31.4906 15.1058L31.483 15.0983Z" fill="#515151"/>
<path d="M20.7315 44C17.6846 44 15.2424 41.5822 15.2424 38.5655V33.8122C15.2424 33.5876 15.4693 33.3631 15.6961 33.3631H25.6838C25.9107 33.3631 26.1375 33.5876 26.1375 33.8122V38.5655C26.2131 41.5822 23.7785 44 20.724 44H20.7315ZM16.2329 34.3362V38.5655C16.2329 41.0582 18.2894 43.0194 20.7315 43.0194C23.1737 43.0194 25.2302 40.9833 25.2302 38.5655V34.3362H16.2329Z" fill="#515151"/>
<path d="M27.1356 39.097H14.3277C14.1008 39.097 13.874 38.8724 13.874 38.6404C13.874 38.4083 14.1008 38.1912 14.3277 38.1912H27.2112C27.438 38.1912 27.6648 38.4158 27.6648 38.6404C27.6648 38.8649 27.438 39.097 27.1356 39.097Z" fill="#515151"/>
<path d="M27.1356 36.6791H14.3277C14.1008 36.6791 13.874 36.4546 13.874 36.23C13.874 36.0054 14.1008 35.7734 14.3277 35.7734H27.2112C27.438 35.7734 27.6648 35.998 27.6648 36.23C27.6648 36.4621 27.438 36.6791 27.1356 36.6791Z" fill="#515151"/>
<path d="M17.6089 22.5689C15.7792 22.5689 14.252 21.0568 14.252 19.2453C14.252 17.4338 15.7792 15.9217 17.6089 15.9217C19.4386 15.9217 20.9659 17.4338 20.9659 19.2453C20.9659 21.0568 19.4386 22.5689 17.6089 22.5689ZM17.6089 16.9098C16.316 16.9098 15.25 17.9653 15.25 19.2453C15.25 20.5253 16.316 21.5883 17.6089 21.5883C18.9018 21.5883 19.9754 20.5328 19.9754 19.2453C20.0511 17.9653 18.9094 16.9098 17.6089 16.9098Z" fill="#515151"/>
<path d="M17.6089 22.5689C15.7792 22.5689 14.252 21.0568 14.252 19.2453C14.252 17.4338 15.7792 15.9217 17.6089 15.9217C19.4386 15.9217 20.9659 17.4338 20.9659 19.2453C20.9659 21.0568 19.4386 22.5689 17.6089 22.5689ZM17.6089 16.9098C16.316 16.9098 15.25 17.9653 15.25 19.2453C15.25 20.5253 16.316 21.5883 17.6089 21.5883C18.9018 21.5883 19.9754 20.5328 19.9754 19.2453C20.0511 17.9653 18.9094 16.9098 17.6089 16.9098Z" fill="black" fill-opacity="0.2"/>
<path d="M23.325 22.5689C21.4953 22.5689 19.968 21.0568 19.968 19.2453C19.968 17.4338 21.4953 15.9217 23.325 15.9217C25.1547 15.9217 26.682 17.4338 26.682 19.2453C26.682 21.0568 25.1547 22.5689 23.325 22.5689ZM23.325 16.9098C22.0321 16.9098 20.966 17.9653 20.966 19.2453C20.966 20.5253 22.0321 21.5883 23.325 21.5883C24.6179 21.5883 25.6915 20.5328 25.6915 19.2453C25.7671 17.9653 24.6254 16.9098 23.325 16.9098Z" fill="#515151"/>
<path d="M20.5047 34.3437C20.2778 34.3437 20.0435 34.1191 20.0435 33.887V19.2453C20.0435 19.0208 20.2703 18.7962 20.5047 18.7962C20.739 18.7962 20.9583 19.0208 20.9583 19.2453V33.887C20.9583 34.1116 20.7315 34.3437 20.5047 34.3437Z" fill="#515151"/>
<path d="M39.1043 29.1337C37.5014 29.1337 36.2085 27.8537 36.2085 26.2668C36.2085 24.6798 37.5014 23.3998 39.1043 23.3998C40.7071 23.3998 42 24.6798 42 26.2668C42 27.8537 40.624 29.1337 39.1043 29.1337ZM39.1043 24.4553C38.0382 24.4553 37.199 25.2862 37.199 26.3416C37.199 27.3971 38.0382 28.228 39.1043 28.228C40.1703 28.228 41.0096 27.3971 41.0096 26.3416C41.0096 25.2862 40.0947 24.4553 39.1043 24.4553Z" fill="#515151"/>
<path d="M3.35698 40.0027C1.52727 40.0027 0 38.4906 0 36.6791C0 34.8676 1.52727 33.3556 3.35698 33.3556C5.18668 33.3556 6.71395 34.8676 6.71395 36.6791C6.71395 38.4906 5.18668 40.0027 3.35698 40.0027ZM3.35698 34.3437C2.06409 34.3437 0.99802 35.3991 0.99802 36.6791C0.99802 37.9592 2.06409 39.0221 3.35698 39.0221C4.64987 39.0221 5.72349 37.9667 5.72349 36.6791C5.72349 35.3916 4.65743 34.3437 3.35698 34.3437Z" fill="#515151"/>
</g>
<defs>
<clipPath id="clip0_333_2048">
<rect width="42" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
