import styled from "styled-components";
export const InputWrapper = styled.div<{ hide?: boolean, minW?: boolean }>`
  min-width:  500px;
  max-width: 1200px;
  width:  ${({minW})=>minW? `350px`:`800px`};
  margin: auto;
  margin-bottom: 30px;
  gap: 10px;
  padding: 8px;
  border-radius: 4px;
  left: ${({hide})=>hide? `0px`:`-196px`};
  position: relative;

  svg {
    padding: 8px
  }
  .ql-container.ql-snow{ border: none !important;}
  .dx-switch {
    margin-right: -110px;
    margin-left: 20px;
  }
  background:white;
  box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
  --tw-shadow: 0 0 15px rgba(0,0,0,.1);
  --tw-shadow-colored: 0 0 15px var(--tw-shadow-color);
  .inputbox {
    max-height: 100px;
    overflow: auto;
  }
`;

export const BottomArea = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: linear-gradient(180deg,hsla(0,0%,100%,0) 13.94%,#fff 54.73%);
  padding-top: 0px;
`