import { BGIconWrapper } from "./styles";

export const AddChatIcon = (props) => {
  return (
    <BGIconWrapper {...props}>
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.02083 10.9375H9.97917V8.0625H12.8542V7.10417H9.97917V4.22917H9.02083V7.10417H6.14583V8.0625H9.02083V10.9375ZM0.875 17.2404V2.42307C0.875 1.98199 1.02274 1.61372 1.31823 1.31823C1.61372 1.02274 1.98199 0.875 2.42307 0.875H16.5769C17.018 0.875 17.3863 1.02274 17.6818 1.31823C17.9773 1.61372 18.125 1.98199 18.125 2.42307V12.7436C18.125 13.1847 17.9773 13.553 17.6818 13.8484C17.3863 14.1439 17.018 14.2917 16.5769 14.2917H3.82372L0.875 17.2404ZM3.41458 13.3333H16.5769C16.7244 13.3333 16.8595 13.2719 16.9824 13.149C17.1052 13.0262 17.1667 12.891 17.1667 12.7436V2.42307C17.1667 2.27564 17.1052 2.1405 16.9824 2.01762C16.8595 1.89476 16.7244 1.83333 16.5769 1.83333H2.42307C2.27564 1.83333 2.1405 1.89476 2.01762 2.01762C1.89476 2.1405 1.83333 2.27564 1.83333 2.42307V14.909L3.41458 13.3333Z"
          fill="white"
        />
      </svg>
    </BGIconWrapper>
  );
};
