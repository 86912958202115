import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Proposal from "@app/components/proposal/landing/proposal";
import ProposalEdit from "@app/components/proposal/edit";
import { useAcquireToken } from "./auth/useAcquireToken";
import FriendlyBlockerPage from "@app/components/friendly-blocker";
import { useQuery } from '@tanstack/react-query';
import { queryAllowedGroups } from "./queries";

const AppRoutes = () => {
  const [requireToken] = useAcquireToken('proposal');
  const { isLoading: groupsIsLoading, data: allowedGroups, refetch: groupsRefetch } = queryAllowedGroups();

  useEffect(() => {
    groupsRefetch();
  }, [allowedGroups]);

  return (
    <Routes>
      {allowedGroups?.groups.length === 0 ? (
        <Route path="*" element={<FriendlyBlockerPage />} />
      ) : (
        <>
          <Route path="/" element={<Proposal />} />
          <Route path="/allModes" element={<Proposal />} />
          <Route path="/edit" element={<ProposalEdit />} />
          <Route path="/:proposalId?/:status?" element={<ProposalEdit />} />
        </>
      )}
    </Routes>
  );
};

export default AppRoutes;
