import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR } from "@app/styles";

export const ChatContentWrapper = styled.div`
  height: calc(100vh - 60px);
  overflow: auto;
  font-size: 14px;
  margin-bottom: 100px;
`;

export const ChatMessageWrapper = styled.div<{ from?: string }>`
  background-color: ${({ from }) =>
    from == "assistant" ? "white" : "transparent"};
  > div {
    min-width: 600px;
    max-width: 1200px;
    margin: auto;
    gap: 20px;
    padding: 30px 0;
    > div:nth-child(1) {
      width: 15%;
      display: flex;
      justify-content: flex-end;
    }
    > div:nth-child(2) {
      width: 85%;
      max-width: 85%;
    }
    > div:nth-child(3) {
      width: 15%;
      min-width: 180px;
      font-size: 12px;
      color: #777;
      font-style: italic;
    }
    .user-actions {
      gap: 10px;
      svg {
        font-size:18px;
      }
    }
    .feedbackBtn {
      svg {
        color: #ccc;
      }
      &.active {
        svg {
          color: ${COLOR.CORN_FLOWER_BLUE};
        }
      }
    }
  }
`;

export const ActionIconButton = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: #00416a;
  cursor: pointer;
  &:hover {
    color: #444;
  }
`;

export const StatsWrapper = styled.div`
  height: 200px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
  button {
    > span {
      font-size: 12px;
      margin-left: 12px;
    }
  }
`;

export const ModeWRapper = styled.div`
  position: fixed;
  width: calc(100% - 235px);
  background: white;
  top: 0;
  padding: 10px 0px;
  left: 235px;
  
`;

export const ContentWrapper = styled.div`
`;


export const Message = styled.div`
  > p:last-child {
    margin-bottom: 0;
  }
`
export const Tags = styled.div`
  font-size: 14px;
`