import React from "react";
import styled from "styled-components";
import { Logo } from "@app/styles/logo"


const UserInitialAvatar = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: blue;
    color: white;
    text-transform: uppercase;
`

const Avatar = (props:any) => {
  return <Logo {...props}/>
};

export default Avatar;
