import * as signalR from '@microsoft/signalr';
import { refreshToken } from '@app/app/auth/authenticate';
import { proposalConfig } from '@app/app/auth/config';   
import { RFP_BACKEND_HOST } from '@app/api';
import { v4 as uuidv4 } from 'uuid';
export const subscribeToSignalr = async (msalInstance, groupName=null) => {
    
    const accessToken = await refreshToken(msalInstance, proposalConfig.SCOPE);    
    const userAccount = msalInstance.getActiveAccount();
    const { username } = userAccount;    
    const sanitizedUsername = username.replace(/[^a-zA-Z0-9]/g, '');
    const target = groupName || sanitizedUsername;
    
    const subscriptionHandle = (`${uuidv4()}-${sanitizedUsername}`).toLocaleLowerCase();    
    const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${RFP_BACKEND_HOST}/websocket/subscribe/${subscriptionHandle}/${target}?token=${accessToken}`)
    .configureLogging(signalR.LogLevel.Information)
    .build();

    return {
        connection,
        subscriptionHandle
    }
}
 
export const websocketActionMap = (dispatch) => (action => {
    dispatch({type: action.type, payload: action.payload});
})
