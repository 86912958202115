import styled from "styled-components";
import Card from "react-bootstrap/Card";

export const FocusCardContainer = styled.div`
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
`
export const FocusCard = styled(Card)`
  width: 222px;
  height: 222px;
  font-size: 50px;
  cursor: pointer;
  &:hover {
    background-color: #E6EEF9 
  }
  > div {
    p {
      font-size: 14px;
      color: #000;
      overflow-wrap: anywhere;
    }
    .detail {
      font-size: 12px;
    }
    .timestamp {
      font-size: 11px;
    }
  }
  @media (max-width: 932px) {
    width: auto; 
    height: auto;
    > div {
      p {
        margin-bottom: 6px;
      }
  }
`

export const StacksContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-grow: 1;
  margin: 3px;
  @media (max-width: 932px) {
    flex-direction: column; 
    text-align-last: center;
  }
`;