import styled from "styled-components";

export const SummaryCard = styled.div`
  .derivedFrom {
    max-width: 500px;
    white-space: break-spaces;
    word-break: break-all;
  }
`;

export const MessageBoard = styled.div`
  min-width: 560px;
  .card-header {
    display: flex;
    height: 90px;
    align-items: center;
  }
  .card-text {
    //   height: 140px;
  }
  .card-body {
    height: 400px;
    overflow: auto;
  }
`;

export const Wrapper = styled.div`
  .content {
    margin: 50px;
    box-sizing: content-box;
    text-align: justify;
    .card-text {
      margin-right: 10px;
    }
  }

  .wintheme {
    height: 400px;
  }
`;


export const ChatQueryMessage = styled.div`
  .query {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }
  .message {
    border-radius: 11px;
    border: 1px solid #e0e0e0;
    padding: 10px;
    margin-left: 20px;
  }
`;