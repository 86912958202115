import axios, { AxiosRequestConfig } from "axios";
import { getAuthFromStorage } from "@app/utils";
import * as CHAT_API from "@app/api/chats/endpoints";
import * as CHAT_TYPES from "@app/api/chats/types";
import * as RFP_API from "@app/api/rfp/endpoints";
import * as RFP_TYPES from "@app/api/rfp/types";
import { ENDPOINTS_URL } from "@app/api/endpoints";
import {
  chatRequestPayloadType,
  generateResponsesRequestPayload,
  requirementExcludePayload,
  requirementFeedbackPayload,
} from "@app/api/types";
import { continueChatRequestType } from "@app/app/mutations";

export const CHAT_BACKEND_HOST = process.env.KCHAT_BACKEND_HOST;
export const RFP_BACKEND_HOST = process.env.RFP_BACKEND_HOST;

//RFP

const standardHeader = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

let controller: AbortController;

export const callApiWithAuthentication = async (
  method: string,
  url: string,
  data: string | FileList | null = null,
  options: { [key: string]: any } = { headers: standardHeader },
  cancelable: boolean = false
) => {
  controller = new AbortController();
  const signal = controller.signal;
  let axiosConfig: AxiosRequestConfig = cancelable
    ? { method, url, signal }
    : { method, url };
  const [user, auth] = getAuthFromStorage();
  if (!("headers" in options)) options["headers"] = {};
  options["headers"]["Authorization"] = `Bearer ${auth}`;
  axiosConfig = { ...axiosConfig, ...options };

  if (data) axiosConfig["data"] = data;
  try {
    const result = await axios(axiosConfig);
    return result.data;
  } catch (e: any) {
    console.log(e)
    if (e.code == "ERR_CANCELED") {
      return [];
    } else {
      throw "error" in e ? e.error.response.data.message : "An unexpected error has occurred. Please contact support team.";
    }
  }
};

export const callApi = async (
  method: string,
  url: string,
  data: string | FileList | null = null,
  options: { [key: string]: any } = { headers: standardHeader }
) => {
  let axiosConfig: AxiosRequestConfig = {
    method,
    url,
  };
  const [user, auth] = getAuthFromStorage();
  options["headers"] = standardHeader;
  axiosConfig = { ...axiosConfig, ...options };
  if (data) axiosConfig["data"] = data;
  try {
    const result = await axios(axiosConfig);
    return result.data;
  } catch (e: any) {
    throw "error" in e ? e.error.response.data.message : "An unexpected error has occurred. Please contact support team.";
  }
};
export const apiConfig = {
  [CHAT_API.ENDPOINT_LIST_CHAT]: async () => {
    return await callApiWithAuthentication(
      "get",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_LIST_CHAT]}`
    );
  },
  [CHAT_API.ENDPOINT_CHAT_STATS]: async (mode: string) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_CHAT_STATS]}`,
      JSON.stringify({ mode })
    );
  },
  [CHAT_API.ENDPOINT_VIEW_CHAT]: async (timestamp_id: string) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_VIEW_CHAT]}`,
      JSON.stringify({ timestamp_id })
    );
  },
  [CHAT_API.ENDPOINT_INITIALIZE_CHAT]: async ({
    id,
    prompt,
    mode,
    skill,
    datasets,
    focus,
  }: continueChatRequestType) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_INITIALIZE_CHAT]}`,
      JSON.stringify({
        timestamp_id: id,
        prompt,
        mode,
        skill,
        datasets,
        focus,
      }),
      { headers: standardHeader },
      true
    );
  },
  [CHAT_API.ENDPOINT_CONTINUE_CHAT]: async ({
    id,
    prompt,
    mode,
    skill,
    datasets,
    focus,
  }: continueChatRequestType) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_CONTINUE_CHAT]}`,
      JSON.stringify({
        timestamp_id: id,
        prompt,
        mode,
        skill,
        datasets,
        focus,
      }),
      { headers: standardHeader },
      true
    );
  },
  [CHAT_API.ENDPOINT_UPLOAD_FILES]: async (files: FileList) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_UPLOAD_FILES]}`,
      files
    );
  },
  [CHAT_API.ENDPOINT_TRACK_FEEDBACK]: async (
    timestamp_id: string,
    index: string,
    feedback: string
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_TRACK_FEEDBACK]}`,
      JSON.stringify({ timestamp_id, index, feedback })
    );
  },
  [CHAT_API.ENDPOINT_GET_FEEDBACK]: async (timestamp_id: string) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_GET_FEEDBACK]}`,
      JSON.stringify({ timestamp_id })
    );
  },
  [CHAT_API.ENDPOINT_LIST_MODES]: async () => {
    return await callApiWithAuthentication(
      "get",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_LIST_MODES]}`
    );
  },
  [CHAT_API.ENDPOINT_LIST_SKILLS]: async () => {
    return await callApiWithAuthentication(
      "get",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_LIST_SKILLS]}`
    );
  },
  [CHAT_API.ENDPOINT_LIST_DATASETS]: async (props: { focus: string}) => {
    const withPrams = props.focus? `?focus=${props.focus}` :``
    return await callApiWithAuthentication(
      "get",
      `${CHAT_BACKEND_HOST}${
        ENDPOINTS_URL[CHAT_API.ENDPOINT_LIST_DATASETS]
      }${withPrams}`
    );
  },
  [CHAT_API.ENDPOINT_LIST_FILES]: async (
    props: CHAT_TYPES.ListFilesRequestType
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${ENDPOINTS_URL[CHAT_API.ENDPOINT_LIST_FILES]}`,
      JSON.stringify(props)
    );
  },
  [CHAT_API.ENDPOINT_LIST_CHAT_CONFIGURATION]: async (
    props: CHAT_TYPES.ListChatConfigurationRequestType
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${
        ENDPOINTS_URL[CHAT_API.ENDPOINT_LIST_CHAT_CONFIGURATION]
      }`,
      JSON.stringify(props)
    );
  },
  [CHAT_API.ENDPOINT_GET_CHAT_CONFIGURATION]: async (
    props: CHAT_TYPES.GetChatConfigurationRequestType
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${
        ENDPOINTS_URL[CHAT_API.ENDPOINT_GET_CHAT_CONFIGURATION]
      }`,
      JSON.stringify(props)
    );
  },
  [CHAT_API.ENDPOINT_UPDATE_CHAT_CONFIGURATION]: async (
    props: CHAT_TYPES.UpdateChatConfigurationRequestType
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${CHAT_BACKEND_HOST}${
        ENDPOINTS_URL[CHAT_API.ENDPOINT_UPDATE_CHAT_CONFIGURATION]
      }`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_PROPOSAL_LIST]: async () => {
    return await callApiWithAuthentication(
      "get",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL]}`
    );
  },
  [RFP_API.ENDPOINT_PROPOSAL_POST]: async (
    props: RFP_TYPES.ProposalRequestType
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL]}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_PROPOSAL_GET]: async (id: string) => {
    return await callApiWithAuthentication(
      "get",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL]}/${id}`
    );
  },
  [RFP_API.ENDPOINT_PROPOSAL_PUT]: async (
    id: string,
    props: RFP_TYPES.ProposalRequestType
  ) => {
    props.id = id;
    return await callApiWithAuthentication(
      "put",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL]}/${id}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_TEMPLATE_LIST]: async () => {
    return await callApiWithAuthentication(
      "get",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_TEMPLATE]}`
    );
  },
  [RFP_API.ENDPOINT_TEMPLATE_POST]: async (props) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_TEMPLATE]}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_CHAT_PROPOSAL_POST]: async (props: {
    query: string;
    requirement_id: string;
    proposal_id: string;
  }) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_CHAT_PROPOSAL]}/${
        props.proposal_id
      }/${props.requirement_id}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_CHAT_PROPOSAL_REQUEST_POST]: async (
    props: chatRequestPayloadType
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${
        ENDPOINTS_URL[RFP_API.ENDPOINT_CHAT_PROPOSAL_REQUEST_POST]
      }/${props.proposal_id}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_CHAT_WINTHEME_POST]: async (props: {
    query: string;
    proposal_id: string;
  }) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${
        ENDPOINTS_URL[RFP_API.ENDPOINT_CHAT_WINTHEME_POST]
      }/${props.proposal_id}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_CHAT_REQUIREMENT_POST]: async (
    props: chatRequestPayloadType
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${
        ENDPOINTS_URL[RFP_API.ENDPOINT_CHAT_REQUIREMENT_POST]
      }/${props.proposal_id}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_CHAT_RESPONSE_POST]: async (
    props: chatRequestPayloadType
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${
        ENDPOINTS_URL[RFP_API.ENDPOINT_CHAT_RESPONSE_POST]
      }/${props.proposal_id}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_RESPONSE_POST]: async (props: generateResponsesRequestPayload) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${
        ENDPOINTS_URL[RFP_API.ENDPOINT_RESPONSE_POST]
      }`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_PROPOSAL_STATUS_GET]: async (props: {
    proposal_id: string;
  }) => {
    return await callApiWithAuthentication(
      "get",
      `${RFP_BACKEND_HOST}${
        ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL_STATUS_GET]
      }/${props.proposal_id}`
    );
  },
  [RFP_API.ENDPOINT_FINALIZE_GET]: async (props: { proposal_id: string }) => {
    return await callApiWithAuthentication(
      "get",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_FINALIZE_GET]}/${
        props.proposal_id
      }`
    );
  },
  [RFP_API.ENDPOINT_REQUIREMENT_EXCLUDE]: async (
    props: requirementExcludePayload
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${
        ENDPOINTS_URL[RFP_API.ENDPOINT_REQUIREMENT_EXCLUDE]
      }/${props.proposal_id}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_REQUIREMENT_FEEDBACK]: async (
    props: requirementFeedbackPayload
  ) => {
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${
        ENDPOINTS_URL[RFP_API.ENDPOINT_REQUIREMENT_FEEDBACK]
      }/${props.proposal_id}`,
      JSON.stringify(props)
    );
  },
  [CHAT_API.CANCEL_REQUEST]: async () => {
    controller.abort();
    return controller.signal.aborted ?? false;
  },
  [RFP_API.ENDPOINT_PROPOSAL_LIST_DATASETS]: async (props: { focus: string}) => {
    const withPrams = props.focus? `?focus=${props.focus}` :``
    return await callApiWithAuthentication(
      "get",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL_LIST_DATASETS]}${withPrams}`
    );
  },
  [RFP_API.ENDPOINT_PROPOSAL_LIST_FILES]: async (props: { focus: string}) => {
    const withPrams = props.focus? `?focus=${props.focus}` :``
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL_LIST_FILES]}${withPrams}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_LIST_ALLOWED_GROUPS]: async () => {
    console.log(`${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_LIST_ALLOWED_GROUPS]}`)
    return await callApiWithAuthentication(
      "get",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_LIST_ALLOWED_GROUPS]}`,
    );
  },
  [RFP_API.ENDPOINT_PROPOSAL_FILE_PUT]: async (
    id: string,
    props: RFP_TYPES.ProposalRequestType
  ) => {
    props.id = id;
    return await callApiWithAuthentication(
      "put",
      `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL_FILE_PUT]}/${id}`,
      JSON.stringify(props)
    );
  },
  [RFP_API.ENDPOINT_PROPOSAL_LIST_MEMBERS]: async (props: {
    group_id: string;
    continuation_token: string;
  }) => {
    console.log("endpoint", `${RFP_BACKEND_HOST}${ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL_LIST_MEMBERS]}/${props.group_id}`)
    return await callApiWithAuthentication(
      "post",
      `${RFP_BACKEND_HOST}${
        ENDPOINTS_URL[RFP_API.ENDPOINT_PROPOSAL_LIST_MEMBERS]
      }/${props.group_id}`,
      JSON.stringify(props)
    );
  },
};
