import * as CHAT_API from "@app/api/chats/endpoints";
import {
  apiConfig,
} from "@app/api/index";
import {
  setChatQuery,
  setCurrentChat,
  setCurrentChatId,
  setCurrentChatProcessing,
  setProcessingQuery,
  toggleLeftPanel as toggleLeftPanelAction,
  updateChatList,
} from "@app/app/actions";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import { useInitialSendMessage } from "@app/app/mutations";
import ChatImg from "@app/styles/assets/chat.svg";
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ChatIcon,
  ChatListWrapper,
  LeftPanel,
  MoreOptions,
  OneLineText,
} from "./styles";

export const ChatPanel = () => {
  const { chatId } = useParams();
  const chat = useAppContext();
  const {
    feature: { proposal },
  } = useAppContext();
  const { hideLeftPanel } = chat;
  const dispatch = useAppDispatchContext();
  const navigate = useNavigate();
  const initialSendMessage = useInitialSendMessage();

  const { isLoading, error, data } = useQuery({
    queryKey: ["chatList"],
    queryFn: () => apiConfig[CHAT_API.ENDPOINT_LIST_CHAT](),
  });

  useEffect(() => {
    dispatch(setCurrentChatId({ id: chatId }));
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(updateChatList(data));
    }
  }, [data]);

  const loadChatMessages = (e: React.MouseEvent<HTMLLIElement>) => {
    const { timestampId } = e.currentTarget.dataset;
    dispatch(setCurrentChatId({ id: timestampId }));
    dispatch(setChatQuery({ query: null }));
    navigate(`/kchat/chat/${timestampId}`); // TODO We should set constants for the routes
  };
  const goNewChat = () => {
    if (chat.currentChat?.id) {
      dispatch(setCurrentChatId({ id: null }));
      dispatch(setChatQuery({ query: null }));
    } else {
      dispatch(setCurrentChat({ id: null, error: null }));
      dispatch(setProcessingQuery({ query: null }));
      dispatch(setCurrentChatProcessing(false));
    }
    navigate("/kchat/chat");
  };
  const toggleLeftPanel = () => {
    // setHideLeftPanel(!hideLeftPanel);
    dispatch(toggleLeftPanelAction(!hideLeftPanel));
  };
  const goToProposal = () => {
    navigate(`/proposal`);
  };
  return (
    <LeftPanel className="border-bottom">
      {hideLeftPanel && (
        <div className="showPanelButton border" onClick={toggleLeftPanel}>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      )}
      <ChatListWrapper className="p-1 d-flex flex-column" hide={hideLeftPanel}>
        <div className="d-flex">
          <div
            className={`d-flex justify-content-between align-items-center text-white mb-2 flex-grow-1 ${initialSendMessage.isLoading? 'disabled': ''}`}
            onClick={goNewChat}
          >
            <OneLineText>Recent Chats</OneLineText>
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <div
            className="d-flex justify-content-between align-items-center text-white mb-2"
            onClick={toggleLeftPanel}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
        </div>

        <ul className="chat-list flex-grow-1">
          {chat.currentChatQuery && chat.isCurrentChatQueryProcessing && (
            <li className={`d-flex align-items-center active`}>
              <ChatIcon src={ChatImg} className="mr-2 mt-1" />
              <OneLineText>{chat.currentChatQuery}</OneLineText>
            </li>
          )}
          {!error &&
            !isLoading &&
            Array.isArray(data) &&
            data?.map((i, index) => (
              <li
                key={index}
                data-timestamp-id={i.timestamp_id}
                className={`d-flex align-items-center ${
                  chat.currentChat?.id == i.timestamp_id ? "active" : ""
                }`}
                onClick={loadChatMessages}
              >
                <ChatIcon src={ChatImg} className="mr-2 mt-1" />
                <OneLineText>{i.prompt}</OneLineText>
              </li>
            ))}
        </ul>
        <MoreOptions>
          {/* {proposal && <div className="d-flex align-items-center" onClick={goToProposal}>
            <ArticleIcon className="mr-2" />
            <OneLineText>Proposals</OneLineText>
          </div>} */}
          {/* <div className="d-flex align-items-center">
          <UserIcon className="mr-2" />
          <OneLineText>Profile</OneLineText>
        </div>
        <div className="d-flex align-items-center">
          <GearIcon className="mr-2" />
          <OneLineText>Settings</OneLineText>
        </div> */}
        </MoreOptions>
      </ChatListWrapper>
    </LeftPanel>
  );
};
