export const MESSAGE_ROLE = {
    ASSISTANT: 'assistant',
    SYSTEM: 'system',
    USER: 'user'
}
export const SP_LADNDING = "https://kbrcorp.sharepoint.com/sites/KBRaiN"
export type chatRequestPayloadType = {
  proposal_id: string;
  filename: string;
  page: string;
  paragraph: string;
  requirement: number;
  requirement_id: string;
  prompt: string;
  editedSummary?: string;
  editedResponse?: string;
  isEditing?: boolean;
  isEditingResponse?: boolean;
};


export type requirementExcludePayload = {
  proposal_id: string;
  filename: string;
  page: number;
  paragraph: number;
  requirement: number;
  isExcluded: boolean;
};
export type requirementFeedbackPayload = {
  proposal_id: string;
  filename: string;
  page: number;
  paragraph: number;
  requirement: number;
  feedback: 1 | -1;
  is_response: boolean;
};
  
  
export type requirementType = {
    filename: string;
    page: number;
    paragraph: number;
    requirement: number;
}
export type responsesRequestPayload = {
    proposal_id: string;
}

export type generateResponsesRequestPayload = {
  proposal_id: string;
  filename: string;
  page:string;
  paragraph: string;
  requirement: number;
  requirement_id: string;
  derived_from_id: string;
}
