import { useEffect, useRef, useState } from "react";
import { TextBox } from "devextreme-react/text-box";
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import ValidationSummary from "devextreme-react/validation-summary";
import { useLocation, useNavigate } from "react-router-dom";
import Validator, { RequiredRule } from "devextreme-react/validator";
import Button from "devextreme-react/button";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import { useCreateProposal, useUpdateProposal } from "@app/app/mutations";
import { queryProposal, queryAllowedGroups, queryMembersList } from "@app/app/queries";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { EDIT_MODE } from "./types";
import Avatar from "../avatar/user";
import { MentionDropdown } from "@app/components/proposal/styles";

const HeaderInfo = () => {
  const state = useAppContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatchContext();
  const {
    currentProposal: { id: currentProposalId, isProcessing, status: currentProposalStatus },
  } = state;
  const { isLoading, data, error, refetch } = queryProposal(currentProposalId);
  const { isLoading: groupsIsLoading, data: allowedGroups, refetch: groupsRefetch } = queryAllowedGroups();
  const { id } = data || {};
  const [proposalName, setProposalName] = useState();
  const [opportunityNumber, setOpportunityNumber] = useState();
  const [formTitle, setFormTitle] = useState('New Proposal')
  const [proposalManager, setProposalManager] = useState("");
  const [captureManager, setCaptureManager] = useState("");
  const [proposalGroup, setProposalGroup] = useState("");
  const createProposal = useCreateProposal(currentProposalId);
  const updateProposal = useUpdateProposal(id);
  const { state: locationState } = useLocation();
  const { mode } = locationState || { mode: EDIT_MODE.EDIT };
  const [showProposalManagerDropdown, setShowProposalManagerDropdown] = useState(false);
  const [showCaptureManagerDropdown, setShowCaptureManagerDropdown] = useState(false);
  const inputRef = useRef(null);
  let [groupMap, setGroup] = useState({});

  useEffect(() => {
    if (error && error.response?.status === 404) {

      navigate("/proposal/edit", { state: { mode: EDIT_MODE.NEW } });
    }
  }, [error])

  useEffect(() => {
    if (currentProposalId && mode == EDIT_MODE.EDIT) {
      refetch();
    }
  }, []);

  useEffect(() => {
    groupsRefetch();
    if (allowedGroups?.groups) {

      groupMap = {
        ...allowedGroups.groups.reduce((acc, group) => {
        acc[group.id] = group.entra_group;
        return acc;
      }, {}),
    };
      setGroup(groupMap);      
      setProposalGroup(allowedGroups.groups[0].entra_group);
    }
  }, [allowedGroups]);
  const { data: membersData } = queryMembersList({ 
    group_id: proposalGroup,
    continuation_token: null
  });
  
  useEffect(() => {
    if (data) {
      setProposalName(data.proposal_name);
      setOpportunityNumber(data.opportunity_number);
      setProposalManager(data.proposal_manager);
      setCaptureManager(data.capture_manager);
      setProposalGroup(data.entra_group);
      setFormTitle('Edit Proposal Header')
    }
  }, [data]);
  const onFormSubmit = (e) => {
    e.preventDefault();

    const payload = {
      proposal_name: proposalName,
      client: "test",
      description: "test",
      proposal_manager: proposalManager,
      capture_manager: captureManager,
      opportunity_number: opportunityNumber,
      entra_group_id: proposalGroup,
    };
    if (id !== currentProposalId) {
      createProposal.mutate({ ...payload, id: currentProposalId, status: "header-info" });
    } else {    
      updateProposal.mutate(payload);
    }
    navigate(`/proposal/${currentProposalId}/document`);
  };

  const onValueChanged = (e, func, setShowDropdown) => {
    func(e.value);
    const cursorPosition = e.event?.target?.selectionStart || 0;
    const lastChar = e.value.charAt(cursorPosition - 1);

    if (lastChar === "@") {
      setShowDropdown(true);
    } else if (!e.value.includes("@")) {
      setShowDropdown(false);
    }
  };

  const handleSelect = (avatar, setFunc, setShowDropdown) => {
    const mentionText = `${avatar.name} `;
    setFunc(mentionText);
    setShowDropdown(false);
    if (inputRef.current) {
      inputRef.current.instance.focus();
    }
  };

  return (
    <div className="content">
      {(
        <form
          action="your-action"
          onSubmit={onFormSubmit}
          className="d-flex flex-column h-100 w-100"
        >
          {( (isLoading && mode == EDIT_MODE.EDIT) || groupsIsLoading) && <LoadIndicator visible={true} />}
          {( (!isLoading || mode == EDIT_MODE.NEW) && !groupsIsLoading) && (
            <div className="d-flex flex-column flex-grow-1 h-100 w-100">
              <div>
                <h3>{formTitle}</h3>
              </div>
              <div className="row d-flex w-100">
                <div className="d-flex flex-column">
                  <label>Proposal Name</label>
                  <TextBox
                    name="proposal_name"
                    value={proposalName}
                    showClearButton={true}
                    valueChangeEvent="keyup"
                    onValueChanged={(e) => onValueChanged(e, setProposalName, () => {})}
                  >
                    <Validator>
                      <RequiredRule message="Proposal Name is required." />
                    </Validator>
                  </TextBox>
                </div>
                <div className="d-flex flex-column">
                  <label>Opportunity Number(s)</label>
                  <TextBox
                    name="opportunity_number"
                    value={opportunityNumber}
                    showClearButton={true}
                    valueChangeEvent="keyup"
                    onValueChanged={(e) => onValueChanged(e, setOpportunityNumber, () => {})}
                  >
                    <Validator>
                      <RequiredRule message="Opportunity Number is required." />
                    </Validator>
                  </TextBox>
                </div>
                <div className="d-flex flex-column">
                  <label>Capture Manager</label>
                  <TextBox
                    ref={inputRef}
                    value={captureManager}
                    onValueChanged={(e) => onValueChanged(e, setCaptureManager, setShowCaptureManagerDropdown)}
                    valueChangeEvent="input"
                  >
                    {showCaptureManagerDropdown && (
                      <MentionDropdown>
                        {membersData?.members.map((avatar)  => (
                          <div
                            key={avatar.id}
                            onClick={() => handleSelect(avatar, setCaptureManager, setShowCaptureManagerDropdown)}
                            style={{ cursor: "pointer" }}
                            className="d-flex align-items-center p-3"
                          >
                            <Avatar email={avatar.displayName !== null ? avatar.displayName : "kbrain@example.com"} />
                            <span className="ml-1">{avatar.displayName !== null ? avatar.displayName : "Unknown"}</span>
                          </div>
                        ))}
                      </MentionDropdown>
                    )}
                    <Validator>
                      <RequiredRule message="Capture Manager is required." />
                    </Validator>
                  </TextBox>
                </div>
              </div>
              <div className="row d-flex w-100">
                <div className="d-flex flex-column">
                  <label>Proposal Manager</label>
                  <TextBox
                    ref={inputRef}
                    value={proposalManager}
                    onValueChanged={(e) => onValueChanged(e, setProposalManager, setShowProposalManagerDropdown)}
                    valueChangeEvent="input"
                  >
                    {showProposalManagerDropdown && (
                      <MentionDropdown>
                        {membersData?.members.map((avatar) => (
                          <div
                            key={avatar.id}
                            onClick={() => handleSelect(avatar, setProposalManager, setShowProposalManagerDropdown)}
                            style={{ cursor: "pointer" }}
                            className="d-flex align-items-center p-3"
                          >
                            <Avatar email={avatar.displayName !== null ? avatar.displayName : "kbrain@example.com"} />
                            <span className="ml-1">{avatar.displayName !== null ? avatar.displayName : "Unknown"}</span>
                          </div>
                        ))}
                      </MentionDropdown>
                    )}
                    <Validator>
                      <RequiredRule message="Proposal Manager is required." />
                    </Validator>
                  </TextBox>
                </div>
                <div className="d-flex flex-column">
                  <label>Group</label>
                  {!groupsIsLoading && (
                    <SelectBox
                      dataSource={allowedGroups?.groups}
                      displayExpr="name"
                      valueExpr="id"
                      placeholder="Proposal Group"
                      defaultValue={allowedGroups?.groups[0].id}        
                      onChange={(e: SelectBoxTypes.ValueChangedEvent) => setProposalGroup(groupMap[e.value])}          
                    />                
                  )}
                </div>
                <div></div>              
              </div>
              <ValidationSummary id="summary" className="mb-3" />
              <Button
                width="150px"
                id="button"
                text="Next"
                type="success"
                useSubmitBehavior={true}
              />
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default HeaderInfo;
