import { useEffect, useState } from "react";
import { DataGrid, Column } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react/load-indicator";
import Validator, { RequiredRule } from "devextreme-react/validator";
import { ProgressBar } from "devextreme-react/progress-bar";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import Button from "devextreme-react/button";
import FileUploader from "devextreme-react/file-uploader";
import Notify from "devextreme/ui/notify";
import { useAppContext } from "@app/app/appContext";
import { useUpdateProposal } from "@app/app/mutations";
import { queryProposal } from "@app/app/queries";
import { PROPOSAL_STATUS } from "./types";
import { convertBase64 } from "@app/utils";
import styled from "styled-components";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ActionIconButton } from "@app/components/chat-content/styles";

import { ArticleIcon } from "@app/styles/icons";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
const Document = () => {
  const state = useAppContext();
  const {
    currentProposal: { id: currentProposalId, isProcessing },
    fileUpdate,
  } = state;
  const [fileList, setFileList] = useState([]);  
  const updateProposal = useUpdateProposal(currentProposalId, true);
  const { isLoading, data, refetch } = queryProposal(currentProposalId);
  useEffect(() => {
    if (currentProposalId) {
      refetch();
    }
  }, [isProcessing, ]);

  useEffect(() => {
    if (data?.files) {
      console.log(fileUpdate)
      data.files = {
        ...data.files,
        ...fileUpdate,
      };
      const newList = Object.entries(data?.files).map((item) => ({
        name: item[0],
        //@ts-ignore
        ...item[1],
      }));

      setFileList(newList);
    } else {
      setFileList([]);
    }
  }, [data, fileUpdate])

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const uploadFiles = Object.entries(e.target.uploadFile.files);
    const files = {};    
    await Promise.all(
      uploadFiles.map(async (file) => {
        const base64All = await convertBase64(file[1]);
        //@ts-ignore
        const base64 = base64All.split(",")[1];
        //@ts-ignore
        const filename = e.target.fileName.value || file[1]?.name;
        let uniqueFilename = filename;
        let counter = 1;
        while (fileList.some(existingFile => existingFile.name === uniqueFilename)) {
            uniqueFilename = `${filename} (${counter})`;
            counter++;
        }

        files[uniqueFilename] = {
          isWinTheme: e.target.isWinTheme.value === "true",
          data: base64,
        };
      })
    );
    let newFiles = uploadFiles.map((file: [string, any]) => ({
        name: file[1]?.name,
        status: "Uploading",
        progress: undefined,
        isWinTheme: e.target.isWinTheme.value === "true",  //important !! isWinThemeInput.checked does not work here. because this is devExtreme component
        url: '',
    }))
    if (data?.files) {
      const oldFiles = Object.entries(data?.files).map((item) => ({
        name: item[0],
        //@ts-ignore
        ...item[1],
      }))
      newFiles = newFiles.concat(oldFiles)
    }
    setFileList(newFiles)
    if (Object.entries(files).length === 0) {
      Notify({
        message: "please select file",
        type: "error",
        displayTime: 3000,
      });
    } else {
      //put files
      updateProposal.mutate({
        files,
        status: PROPOSAL_STATUS.REQUIREMENT_REVIEW,
        isExcluded: true,
      });
    }
  };

  const dataEntryRow = (e) => {
    if (e.rowType == "data" && e.rowIndex % 2 === 0) {
      e.rowElement.style.backgroundColor = "#f5f5f5";
    }
  };

  const getFiles = () => {
    return fileList
  };
  const openFile = (e) => {
    const url = e.currentTarget.dataset.url; 
    window.open(url, "_blank");
  };
  const deleteFile = (e) => {
    const { name } = e.data;
    updateProposal.mutate({
      files: { [name]: { ...e.data, delete: true } },
      isExcluded: true,
    });

    const newFiles = fileList.filter((file) => file.name !== name);
    setFileList(newFiles);
  };

  const uncheckedLabel = { "aria-label": "Unchecked" };
  const Wrapper = styled.div`
    .dx-fileuploader-content {
      display: flex;
      > div {
        padding-top: 20px !important;
      }
    }
    .upload-button {
      margin-top: 30px;
    }

    svg {
      cursor: pointer;
      color: #0078d4;
    }
  `;
  return (
    <Wrapper className="content">        
        <form
          action="your-action"
          onSubmit={onFormSubmit}
          className="d-flex flex-column w-100 mb-3"
        >
          <h3>Upload Documents</h3>
          {(isLoading && <LoadIndicator id="load-indicator" visible={true} />) || (  
            <>
              <div className="d-flex align-items-start mb-3">
                <div className="d-flex align-items-end">
                  <div className="d-flex flex-column">
                    <label>File Name</label>
                    <TextBox
                      name="fileName"
                      showClearButton={true}
                      valueChangeEvent="keyup"
                    ></TextBox>
                  </div>
                </div>
                <div className="fileuploader-container w-25">
                  <FileUploader
                    selectButtonText="Select file"
                    labelText=""
                    name="uploadFile"
                    uploadMode="useForm"
                    accept="application/pdf,image/jpeg,image/png,image/bmp,image/tiff,image/heif,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/html"
                    allowedFileExtensions={[".pdf", ".jpg", ".jpeg", ".png", ".bmp", ".tiff", ".heif", ".docx", ".xlsx", ".pptx", ".html"]}
                    multiple={true}
                    onFilesUploaded={(e) => {
                      console.log(e);
                    }}
                  >
                    <Validator>
                      <RequiredRule message="Files are required." />
                    </Validator>
                  </FileUploader>

                  
                </div>
                <Button
                  className="upload-button"
                  text="Upload Selected File(s)"
                  type="success"
                  useSubmitBehavior={true}
                />
              </div>
              <div className="d-flex">
                <label>is capture doc?</label>
                <CheckBox
                  name={"isWinTheme"}
                  className="mb-3"
                  elementAttr={uncheckedLabel}
                />
              </div>
            </>
          )}
        </form>   
        {(!isLoading &&             
          <DataGrid
            id="dataGrid"
            dataSource={getFiles()}
            keyExpr="name"
            showBorders={true}
            allowColumnReordering={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            onRowPrepared={dataEntryRow}
            width={"100%"}
            className="mt-3"
          >
            <Column
              dataField="name"
              caption="File Name"
              cellRender={(data) => {
                return (
                  <div data-url={data.data.url} onClick={openFile}>
                    {data.data.name}
                  </div>
                );
              }}
            />
            <Column dataField="url" caption="Link" width={50} 
              cellRender={(data) => (
                data.data.status !== 'failed' ? (
                  data.data.url === '' ? 
                  (<LoadIndicator id="load-indicator" visible={true} />) 
                  : (<ArticleIcon onClick={() => window.open(data.data.url, "_blank")} />)
                ) : (
                  <span>X</span>
                )
              )}
            />
            <Column dataField="status" caption="Status" width={200} />
            <Column
              dataField="isWinTheme"
              caption="Capture Doc?"
              width={100}
              cellRender={(data) =>
                data.data.isWinTheme && <ActionIconButton icon={faCheck} />
              }
            />
            <Column
              dataField="progress"
              caption="Progress"
              width={100}
              cellRender={(data) => ( 
                data.data.status !== 'failed' && data.data.status !== 'processed' &&
                <ProgressBar
                  id="progress-bar-status"
                  width="90%"
                  min={0}
                  value={Math.floor(data.data.progress * 100)}
                />
              )}
            />
            <Column
              width={100}
              cellRender={(data) => {
                return (
                  <Button
                    text="Delete"
                    type="danger"
                    onClick={() => {
                      if (confirm('Are you sure you want to delete this file from the proposal? This will remove all generated requirements and responses for this file from the proposal immediately, and cannot be undone.')) {
                        deleteFile(data);
                      }
                    }}
                  />
                );
              }}
            />
          </DataGrid>        
        )}
    </Wrapper>
  );
};

export default Document;
