import { FixWidthContentWrapper, GrayPage } from "@app/styles";
import Card from "react-bootstrap/Card";
import { LightBlubIcon, ScopeIcon, BrainIcon } from "@app/styles/icons";
import { FocusCard, FocusCardContainer, StacksContainer } from "@app/components/landing/styles";
import { HistoryChatList } from "@app/components/focus-chat/history";
import { FocusChatList } from "@app/components/focus-chat/list";
import { useAppDispatchContext, useAppContext } from "@app/app/appContext";
import {
  setProcessingQuery,
  setChatQuery,
  setCurrentChat,
  setCurrentChatId,
  updateChatList,
  toggleLeftPanel as toggleLeftPanelAction,
  setCurrentChatProcessing,
} from "@app/app/actions";
import { useNavigate } from "react-router-dom";

import {
  IntroWrapper,
  ReleaseButton,
  ReleaseInfoIcon,
  PanelWrapper,
  MainPanel,
  TitleBlock,
  Stack,
} from "@app/components/chat-introduction/styles";
const Home = () => {
  const dispatch = useAppDispatchContext();
  const navigate = useNavigate();
  const chat = useAppContext();

  const goNewChat = () => {
    if (chat.currentChat?.id) {
      dispatch(setCurrentChatId({ id: null }));
      dispatch(setChatQuery({ query: null }));
    } else {
      dispatch(setCurrentChat({ id: null, error: null }));
      dispatch(setProcessingQuery({ query: null }));
      dispatch(setCurrentChatProcessing(false));
    }
    navigate(`/kchat/chat${chat.isFocusedChat ? '?support-focus-chat=true' : ''}`);
  };

  const goRFP = () => {
    navigate("/proposal");
  }
  return (
    <GrayPage>
      <FixWidthContentWrapper paddingBottom="80px" className="pt-3">
        <Card>
          <div className="d-flex align-items-center">
            <h5 className="m-3">Welcome to KBRaiN&#8480;</h5>
            { <div>
              <button type="button" className="btn btn-outline-primary btn-sm" onClick={goRFP}>
          <span className="mr-1"> Go RFP</span>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="arrow-left"
            className="svg-inline--fa fa-arrow-left rotate-180 "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width={'12'}
            style={{transform: 'rotate(180deg)'}}
          >
            <path
              fill="currentColor"
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
            ></path>
          </svg>
        </button></div>}
          </div>
          <StacksContainer>
            <Stack>
              <div className="h-100">
                <LightBlubIcon />
                <span>Examples</span>
                <p>Can you summarize the documents I have uploaded?</p>
                <p>What does KBR know about gauge system design?</p>
                <p>How do I make an HTTP request in Javascript?</p>
              </div>
            </Stack>
            <Stack>
              <div className="h-100">
                <ScopeIcon />
                <span>Capabilities</span>
                <p>Remembers what user said earlier in the conversation.</p>
                <p>Allows user to provide follow-up corrections.</p>
                <p>Trained to decline inappropriate requests.</p>
              </div>
            </Stack>
            <Stack>
              <div className="h-100">
                <BrainIcon />
                <span>Limitations</span>

                <p>Treat all output as a draft.</p>
                <p>May occasionally produce harmful instructions or biased.</p>
                <p>Limited knowledge of world and events after 2021.</p>
              </div>
            </Stack>
          </StacksContainer>
        </Card>
        <Card className="mt-3">
          <h5 className="m-3">Focused-Chat</h5>
          <FocusCardContainer className="d-flex m-3">
            <FocusChatList />
          </FocusCardContainer>
        </Card>
        <Card className="mt-3">
          <h5 className="m-3">Chat History</h5>
          <FocusCardContainer className="d-flex m-3">
            {/* <FocusCard onClick={goNewChat}>
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                +
              </Card.Body>
            </FocusCard> */}
            <HistoryChatList />
          </FocusCardContainer>
        </Card>
      </FixWidthContentWrapper>
    </GrayPage>
  );
};
export default Home;
