import { useEffect, useState } from "react";
import SelectBox from "devextreme-react/select-box";
import Button from "devextreme-react/button";
import { ButtonGroup } from "devextreme-react/button-group";
import FileUploader from "devextreme-react/file-uploader";
import { queryTemplateList, queryProposal } from "@app/app/queries";
import { convertBase64 } from "@app/utils";
import Notify from "devextreme/ui/notify";
import { useUploadTemplate, useUpdateProposal } from "@app/app/mutations";
import Validator, { RequiredRule } from "devextreme-react/validator";
import { useAppContext } from "@app/app/appContext";
import { LoadIndicator } from "devextreme-react/load-indicator";

const Template = () => {
  const state = useAppContext();
  const {
    currentProposal: {
      id: currentProposalId,
      status: currentProposalStatus,
      isProcessing,
    },
  } = state;
  const uploadTemplate = useUploadTemplate();
  const updateProposal = useUpdateProposal(currentProposalId);
  const { isLoading, error, data } = queryTemplateList();
  const {
    isLoading: proposalIsLoading,
    error: proposalError,
    data: proposalData,
    refetch,
  } = queryProposal(currentProposalId);
  const [selectedOption, setSelectedOption] = useState(
    "Select Existing Template"
  );
  const [seletedTemplateId, setSeletedTemplateId] = useState();

  const selectOptions = [
    { text: "Select Existing Template" },
    { text: "Add New Template" },
  ];
  useEffect(() => {
    if (currentProposalId) {
      refetch();
    }
  }, []);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (selectedOption === selectOptions[1].text) {
      const uploadFiles = Object.entries(e.target.uploadFile.files);
      const files = [];
      await Promise.all(
        uploadFiles.map(async (file) => {
          const base64All = await convertBase64(file[1]);
          //@ts-ignore
          const base64 = base64All.split(",")[1];
          //@ts-ignore
          files.push({ name: file[1]?.name, data: base64 });
        })
      );
      if (Object.entries(files).length === 0) {
        Notify({
          message: "please select file",
          type: "error",
          displayTime: 3000,
        });
      } else {
        //put files
        uploadTemplate.mutate(files[0]);
      }
    } else {
      const id = e.target.template_id.value;
      updateProposal.mutate({ template_id: id });
    }
  };
  const onSelectedOptionChanged = (e) => {};
  const itemClick = (e) => {
    setSelectedOption(e.itemData.text);
  };
  useEffect(() => {
    if (proposalData && proposalData.template_id) {
      setSeletedTemplateId(proposalData.template_id);
    }
  }, [proposalData]);
  return (
    <div className="content">
      {(isLoading || isProcessing) && (
        <div className="loading d-flex flex-column align-items-center">
          <LoadIndicator />
        </div>
      )}
      {!isLoading && !isProcessing && (
        <form
          action="your-action"
          onSubmit={onFormSubmit}
          className="d-flex flex-column h-100 w-100"
        >
          <h4>Choose a template</h4>
          <div className="d-flex flex-column h-100 mt-3">
            <div className="flex-grow-1">
              <ButtonGroup
                items={selectOptions}
                keyExpr="text"
                stylingMode="outlined"
                selectionMode="single"
                onItemClick={itemClick}
                selectedItemKeys={[selectedOption]}
                onSelectionChanged={onSelectedOptionChanged}
                className="mb-3"
              />
              {selectedOption === selectOptions[0].text && (
                <SelectBox
                  name={"template_id"}
                  value={seletedTemplateId}
                  items={data}
                  placeholder="Choose Template"
                  displayExpr="name"
                  valueExpr="id"
                  showClearButton={true}
                  onValueChange={(e) => setSeletedTemplateId(e)}
                >
                  <Validator>
                    <RequiredRule message="Template is required." />
                  </Validator>
                </SelectBox>
              )}
              {selectedOption === selectOptions[1].text && (
                <div className="d-flex">
                  <div className="fileuploader-container">
                    <FileUploader
                      selectButtonText="Select file"
                      labelText=""
                      name="uploadFile"
                      uploadMode="useForm"
                    >
                      <Validator>
                        <RequiredRule message="File is required." />
                      </Validator>
                    </FileUploader>
                  </div>
                  <div>
                    <Button
                      className="button mt-3"
                      text="Update profile"
                      type="success"
                      useSubmitBehavior={true}
                    />
                  </div>
                </div>
              )}
            </div>
            <Button
              width="150px"
              id="button"
              text="Next"
              type="success"
              useSubmitBehavior={true}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default Template;
