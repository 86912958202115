import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export const FixedContentWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  font-family: Lato;
  color: #3e3e3e;
`;

export const TopNavigation = styled.div`
margin-top:55px;
  height: 98px;
  border-bottom: 1px solid #ccc;
  > div {
    > div {
      flex: 1;
    }
  }
`;
export const NavWrapper = styled.div``;
export const StatusIndicator = styled.div<{ active?: boolean }>`
  min-width: 200px;
  .circle {
    display: block;
    padding: 10px;
    background: white;
    > svg {
      background-color: ${({ active }) => (active ? "#4EA944" : "white")};
      color: white;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border: 2px solid #4ea944;
    }
  }
  z-index: 9;
  cursor: ${({ active }) => (active ? "initial" : "pointer")};
`;

export const ProgressBar = styled.div`
  position: relative;
  .line {
    height: 1px;
    position: absolute;
    width: 100%;
    background: rebeccapurple;
    top: 20px;
    width: 750px;
    left: calc(50% - 375px);
  }
`;

export const BackButton = styled.div`
  width: 80px;
  border-right: 1px solid #ccc;
`;
export const PrintOrDownloadButton = styled.div`
  font-size: 24px;
`;

export const ProposalContentWraper = styled.div`
  background-color: #f5f5f5;
  .content {
    background-color: white;
    max-width: 1200px;
    width: 100%;
    height: calc( 100% - 100px);
    margin: auto;
    box-sizing: border-box;
    padding: 50px;
    form {
      > div {
        gap: 20px;
      }
      div.row {
        gap: 20px;
        margin-right: 0px;
        margin-left: 0px;
        > div {
          flex: 1;
        }
      }
    }
  }
`;

export const MentionDropdown = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  min-width: 200px;
`;

export const MentionDropdownInverse = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  min-width: 200px;
  transform: translateY(-100%);
`;

export const MentionDropdownInverseCenter = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  max-height: 200px;
  overflow-y: auto;
  min-width: 500px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
`;
