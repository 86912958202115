import styled from "styled-components";
import { COLOR } from "@app/styles";

export const SummaryCard = styled.div`
  &.active {
    border: 2px solid green;
  }
  .derivedFrom {
    max-width: 500px;
    white-space: break-spaces;
    word-break: break-all;
  }
  .card-text {
    text-align: justify;
    margin-right: 10px;
  }
  .feedbackBtn {
    svg {
      color: #ccc;
    }
    &.active {
      svg {
        color: ${COLOR.CORN_FLOWER_BLUE};
      }
    }
  }
`;

export const MessageBoard = styled.div`
  .card-header {
    display: flex;
    height: 90px;
    align-items: center;
  }
  .card-text {
    //   height: 140px;
  }
  .card-body {
    overflow: auto;
    height: calc( 100vh - 355px);
  }
`;

export const Wrapper = styled.div`
  .content {
    margin: 50px;
    box-sizing: content-box;
  }

  .card-title {
    magin-top: 0px;
  }

  .response {
    height: 300px;
  }
`;
export const RightFixedPanel = styled.div`
  min-width: 400px;
  > div {
    max-width: 400px;
    position: fixed;
    right: 0;
  }
`;

export const ChatQueryMessage = styled.div`
  .query {
    padding: 10px;
  }
  .message {
    border-radius: 11px;
    border: 1px solid #e0e0e0;
    padding: 10px;
    margin-left: 20px;
  }
`;

export const LinkButton = styled.div`
    text-decoration: underline;
    margin-left: 10px;
    margin-top: 5px;
    cursor: pointer;
`;
