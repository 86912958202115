import { COLOR } from "@app/styles";
import { styled } from "styled-components";
import { Popover } from "devextreme-react/popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const IntroWrapper = styled.div`
  background-color: ${COLOR.LIGHT_GRAY};
`;
export const ReleaseInfoIcon = styled(FontAwesomeIcon)`
  font-size: 8px;
  color: #ccc;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 100px;
  padding: 2px 4px;
`;

export const ReleaseButton = styled.button`

    align-items: center;
    display: flex;
    padding: 0;
    border-radius: 100px;
    padding: 0px 5px;
    line-height: 12px;
  outline: 0 !important;
  >span {
    font-size: 12px !important;
    line-height: 18px !important;
  }
`;

export const PanelWrapper = styled.div`
  display: flex;
  gap: 30px;
  max-width: calc(900px + 350px);
  margin: auto;
`;
export const MainPanel = styled.div`
  max-width: 554px;
  height: 100%;
  background: white;
  flex-grow: 1;
  padding: 20px;
  gap: 20px;
  min-width: 60vw;
  margin: auto;
  > div:nth-child(2),
  > div:nth-child(3) {
    gap: 20px;
  }
`;
export const SidePanel = styled.div`
  width: 350px;
  height: 100%;
  background: white;
  padding: 20px;
  gap: 20px;
`;

export const Stack = styled.div`
  flex: 1;
  align-items: stretch;
  > div {
    padding: 20px;
    border: 2px solid ${COLOR.LIGHT_GRAY};
    &:first-child {
      min-height: 180px;
    }
    > span {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    > p {
      font-family: Lato;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: ${COLOR.LIGHTER_GRAY};
    }
  }
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const TitleBlock = styled.div`
  span {
    font-family: Lato;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
export const TextUnderlinedButton = styled.div`
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  text-decoration: underline;
  color: ${COLOR.LIGHT_BLUE};
`;

export const ReleasePopover = styled(Popover)`
  p {
    font-weight: bold !important;
  }
`;
