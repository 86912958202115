import { Tags } from "@app/components/chat-content/styles";
import { CHAT_MODE } from "@app/app/types";
type ItemType = {
  item: {
    mode: string;
    skill: string;
  };
};
const MessageTags = ({ item }: ItemType) => {
  const itemType = (mode: string) => {
    if (mode == CHAT_MODE.KBR) {
      return "info";
    } else {
      return "secondary";
    }
  };
  return (
    <Tags>
      {item.mode && (
        <span className={`badge badge-${itemType(item.mode)} mr-1`}>
          {item.mode.toUpperCase()}
        </span>
      )}
      {item.skill && <span className="badge badge-warning">{item.skill}</span>}
    </Tags>
  );
};
export default MessageTags;
