import {
  ChatPanel,
  ChatIntroduction,
  ChatInput,
  ChatContent,
} from "@app/components";
import AvatarAssisant from "@app/components/avatar/assistant";
import { ContentWrapper } from "@app/styles";
import { useIsAuthenticated } from "@azure/msal-react";
import { CHAT_MODE, chatModeOptions } from "@app/app/types";
import { useAppDispatchContext, useAppContext } from "@app/app/appContext";
import { setCurrentChatMode } from "@app/app/actions";
import {
  ActionIconButton,
  ChatMessageWrapper,
} from "@app/components/chat-content/styles";
import { MESSAGE_ROLE } from "@app/api/types";
import Avatar from "../avatar/user";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { TreeList, Selection, Column } from "devextreme-react/tree-list";
import { useLocation } from "react-router-dom";

const Home = () => {
  const chat = useAppContext();
  const dispatch = useAppDispatchContext();
  const isAuthenticated = useIsAuthenticated();
  const {
    currentChat,
    isCurrentChatQueryProcessing,
    currentChatQuery,
    processingMessage,
    auth: { user },
  } = chat;
  const isFileMode = false;
  const { id } = currentChat || {};
  const location = useLocation();
  const employees = [
    {
      ID: 1,
      FileName: "John Heart",
    },
    {
      ID: 2,
      FileName: "Samantha Bright",
    },
    {
      ID: 3,
      FileName: "Arthur Miller",
    },
  ];

  const chatError = (chat.currentChat || {}).error;
  return (
    <div className="d-flex h-100">
      <ChatPanel />
      <ContentWrapper
        className="d-flex flex-column flex-column flex-grow-1 h-100 overflow-auto"
        paddingBottom="80px"
        gap="0"
      >
        {id && <ChatContent />}
        {!id && !isCurrentChatQueryProcessing && !chatError && !isFileMode && (
          <ChatIntroduction />
        )}
        {/* {!isFileMode && !id && !isCurrentChatQueryProcessing && <ChatIntroduction />} */}
        {processingMessage && (
          <ChatMessageWrapper from={MESSAGE_ROLE.ASSISTANT}>
            <div className="d-flex">
              <div>
                <Avatar email={user.username} />
              </div>
              <div className="flex-grow-1">
                {processingMessage}
              </div>
              <div>
                <span>Now</span>
              </div>
            </div>
          </ChatMessageWrapper>
        )}
        {isCurrentChatQueryProcessing && !chatError && (
          <ChatMessageWrapper from="assistant">
            <div className="d-flex">
              <div>
                <AvatarAssisant />
              </div>
              <div className="flex-grow-1">
                <ActionIconButton icon={faComment} className="mt-1 blink" />
              </div>
              <div>
                <span>Now</span>
              </div>
            </div>
          </ChatMessageWrapper>
        )}
        {chatError && !isCurrentChatQueryProcessing && (
          <ChatMessageWrapper from="assistant">
            <div className="d-flex">
              <div>
                <AvatarAssisant />
              </div>
              <div className="flex-grow-1 text-danger">{chatError}</div>
              <div>
                <span>Now</span>
              </div>
            </div>
          </ChatMessageWrapper>
        )}
        {isFileMode && (
          <div className="d-flex w-75 m-auto">
            <TreeList
              id="employees"
              dataSource={employees}
              columnAutoWidth={true}
              selectedRowKeys={[1]}
              keyExpr="ID"
            >
              <Selection recursive={false} mode="multiple" />
            </TreeList>
          </div>
        )}
        <ChatInput />
      </ContentWrapper>
    </div>
  );
};
export default Home;
