export const PROPOSAL_STATUS = {
  HEADER_INFO: "header-info",
  DOCUMENT: "document",
  TEMPLATE: "template",
  REQUIREMENT_REVIEW: "requirement-review",
  WIN_THEME_REVIEW: "win-theme-review",
};
export type statusType =
  | typeof PROPOSAL_STATUS.HEADER_INFO
  | typeof PROPOSAL_STATUS.DOCUMENT
  | typeof PROPOSAL_STATUS.TEMPLATE
  | typeof PROPOSAL_STATUS.REQUIREMENT_REVIEW;

export const PROPOSAL_PROCESS_STATUS = {
  SUBMITTED: 'submitted',
  PENDING_REVIEW: 'pending review',
  CLOSE: 'close'
}
export type proposalType = {
  id: string,
  proposal_number; string,
  client: string,
  description: string,
  status:  typeof PROPOSAL_PROCESS_STATUS.SUBMITTED | typeof PROPOSAL_PROCESS_STATUS.PENDING_REVIEW | typeof PROPOSAL_PROCESS_STATUS.CLOSE
  date: string,
  proposal_manger: string,
  capture_manager: string,
  page_limit: string,
  font: string,
}

export const EDIT_MODE = {
  EDIT: 'edit',
  NEW: 'new'
}