import React from "react";
import styled from "styled-components";
import BlockerIcon from "../../../../kauth/src/assets/Lock.png"; 

const PageContainer = styled.div`
  min-height: 100vh; 
  background: radial-gradient(circle, #0070fd 10%, #050740 100%);
  color: white;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 10vh 30px;
  box-sizing: border-box; 
`;

const BlockerHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 30px;
  padding-top: 0; 
  padding-left: 30px; 
  margin-bottom: 5vh; 
`;

const BlockerIconImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BlockerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center;
`;

const Message = styled.h2`
  font-size: 100px;
  margin: 0;
  font-weight: 700; 
  padding-right: 100px;
  text-align: right;
`;

const SubMessage = styled.p`
  font-size: 40px;
  margin: 10px 0;
  padding-inline: 200px;
  padding-top: 30px;
  text-align: center; 
`;

const FriendlyBlockerPage = () => {
  return (
    <PageContainer>

      <BlockerContent>
        <ContentWrapper>
          <Message><p>Access</p> Denied</Message>
          <ImageWrapper>
            <img
              src={BlockerIcon}
              alt="Access Restricted"
              style={{ width: "300px", height: "300px" }}
            />
          </ImageWrapper>
        </ContentWrapper>
        <SubMessage>
          You are not authorized to view this content. Please check your
          permissions or contact support for assistance.
        </SubMessage>
      </BlockerContent>
    </PageContainer>
  );
};

export default FriendlyBlockerPage;
