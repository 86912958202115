export type LoginConfig = {
  BACKEND_HOST: string,
  CLIENT_ID: string,
  TENANT_ID: string,
  SCOPE: Array<string>
}

const chatConfig: LoginConfig = {
  BACKEND_HOST: process.env.KCHAT_BACKEND_HOST ||
    "https://kbrain-kchat-services.azurewebsites.net",
  CLIENT_ID: process.env.CLIENT_ID || "a1db5b17-78b4-4a83-9054-d7b715edc4c4",
  TENANT_ID: process.env.TENANT_ID || "9e52d672-a711-4a65-ad96-286a3703d96e",
  SCOPE: [
    process.env.SCOPE ||
    "api://a1db5b17-78b4-4a83-9054-d7b715edc4c4/user_impersonation"
  ]
}

export const proposalConfig: LoginConfig  = {
  BACKEND_HOST: process.env.RFP_BACKEND_HOST || "https://localhost:7072",
  CLIENT_ID: process.env.RFP_CLIENT_ID || "4da68801-bf7a-4cf3-b74f-0263165f190d",
  TENANT_ID: process.env.TENANT_ID || "9e52d672-a711-4a65-ad96-286a3703d96e",
  SCOPE: [`api://kbrain-rfp-${process.env.STAGE === "production" ? 'prod' : 'stage'}/user_impersonation`],
}

export const msalConfig = {
  chatMsalConfig: {
    auth: {
      clientId: chatConfig.CLIENT_ID,
      authority: `https://login.microsoftonline.com/${chatConfig.TENANT_ID}`,
      redirectUri: `https://${window.location.host}/kchat`,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  },
  chatLoginRequest: {
    scopes: chatConfig.SCOPE
  },
  proposalMsalConfig: {
    auth: {
      clientId: proposalConfig.CLIENT_ID,
      authority: `https://login.microsoftonline.com/${proposalConfig.TENANT_ID}`,
      redirectUri: `https://${window.location.host}/proposal`,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  },
  proposalLoginRequest: {
    scopes: proposalConfig.SCOPE
  }
}