import { useParams } from "react-router-dom";
import {
  ChatPanel,
  ChatInput,
  ChatContent,
} from "@app/components";
import { ContentWrapper } from "@app/styles";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import NewChatContent from "@app/components/chat-content/new"
import { useIsAuthenticated, useAccount,useMsal } from "@azure/msal-react";


const Chating = () => {
  const chat = useAppContext();
  const {
    currentChat,
  } = chat;
  const { id } = currentChat || {}
  const { chatId } = useParams()
  return (
    <div className="d-flex h-100">
      <ChatPanel />
      <ContentWrapper className="d-flex flex-column justify-content-betweend-flex flex-column justify-content-between flex-grow-1 h-100 overflow-auto">
        {!chatId && !id && <NewChatContent/> } 
        { (chatId || id) && <ChatContent /> }
        <ChatInput />
      </ContentWrapper>
    </div>
  );
};
export default Chating;
