import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { useAppDispatchContext } from "@app/app/appContext";
import { setAuth } from "@app/app/actions";
import { msalConfig } from "@app/app/auth/config";


export const useAcquireToken = (
  loginType: string,
) => {
  const { instance } = useMsal();
  const dispatch = useAppDispatchContext();
  const [authToken, setAuthToken] = useState<string>();
  const [user, setUser] = useState<AccountInfo>();

  const requireToken = async () => {
    const accounts = instance.getAllAccounts();
    const loginRequest = msalConfig[`${loginType}LoginRequest`];

    if (accounts.length == 0) {
      instance.acquireTokenRedirect(loginRequest);
    } else {
      try {
        const result: AuthenticationResult = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        const { accessToken, account } = result;
        window.localStorage.setItem("token", accessToken);
        window.localStorage.setItem("user", JSON.stringify(account));
        setAuthToken(accessToken);
        setUser(account);
        dispatch(
          setAuth({ authToken: result.accessToken, user: result.account })
        );
      } catch (e) {
        instance.acquireTokenRedirect(loginRequest);
      }
    }
  };
  useEffect(() => {
    requireToken();
  }, []);
  return [requireToken];
};
