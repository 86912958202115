import { Popover } from "devextreme-react/popover";
import { LightBlubIcon, ScopeIcon, BrainIcon } from "@app/styles/icons";
import {
  IntroWrapper,
  ReleaseButton,
  ReleaseInfoIcon,
  PanelWrapper,
  MainPanel,
  TitleBlock,
  Stack,
} from "@app/components/chat-introduction/styles";
import ReleaseContent from "@app/components/chat-introduction/release-note";

export const ChatIntroduction = () => {
  return (
    <IntroWrapper className="flex-grow-1">
      <PanelWrapper className="d-flex">
        <MainPanel className="d-flex flex-column">
          <div className="d-flex justify-content-between">
            <TitleBlock className="d-flex align-items-center ml-3 mt-3 ">
              <span className="mr-3"> Welcome to KBRaiN&#8480;</span>
            </TitleBlock>
            <ReleaseContent />
          </div>
          <div className="d-flex flex-grow-1 m-3 ">
            <Stack>
              <div className="h-100">
                <LightBlubIcon />
                <span>Examples</span>
                <p>Can you summarize the documents I have uploaded?</p>
                <p>What does KBR know about gauge system design?</p>
                <p>How do I make an HTTP request in Javascript?</p>
              </div>
            </Stack>
            <Stack>
              <div className="h-100">
                <ScopeIcon />
                <span>Capabilities</span>
                <p>Remembers what user said earlier in the conversation.</p>
                <p>Allows user to provide follow-up corrections.</p>
                <p>Trained to decline inappropriate requests.</p>
              </div>
            </Stack>
            <Stack>
              <div className="h-100">
                <BrainIcon />
                <span>Limitations</span>

                <p>Treat all output as a draft.</p>
                <p>May occasionally produce harmful instructions or biased.</p>
                <p>Limited knowledge of world and events after 2021.</p>
              </div>
            </Stack>
          </div>
          {/* <div className="d-flex m-3">
            <Stack>
              <div>
                <span className="mt-0">Tools & References</span>
                <p className="mb-1">
                  <a
                    href="https://kbrcorp.sharepoint.com/sites/KBRaiN/SitePages/Fact-Sheet.aspx?xsdata=MDV8MDF8fDMxMTVhNmZhZGZjYTRiNmViMzc2MDhkYmUwYTk4YzMxfDllNTJkNjcyYTcxMTRhNjVhZDk2Mjg2YTM3MDNkOTZlfDB8MHw2MzgzNTA3OTEwMDkyMTk0NDZ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPak13TlRWbU5qWmpaamd4TXpRd056azVaV1F6WmpKaE16WTVOalkxWTJRM1FIUm9jbVZoWkM1Mk1pOXRaWE56WVdkbGN5OHhOams1TkRneU16QXdNVEV4fDUyMjllNzc1ZTkxYjRiZWZiMzc2MDhkYmUwYTk4YzMxfDE1ZTFkOGU4YTFjNTQ3YTg4ZTI4YTQxYjE0YjhjMmQ2&sdata=Vm84YjY5RjRjZ0xZU1hXc2ZxbTMvc1pNeE0zeHJLTi9YbnJvTGkza1pKcz0%3D&ovuser=9e52d672-a711-4a65-ad96-286a3703d96e%2CDonna.Vetrano%40kbr.com"
                    target="_blank"
                  >
                    Quick Start Guide
                  </a>
                </p>
                <p className="mb-1">
                  <a
                    href="https://kbrcorp.sharepoint.com/sites/KBRaiN/SitePages/Prompts-Guide.aspx?xsdata=MDV8MDF8fDMxMTVhNmZhZGZjYTRiNmViMzc2MDhkYmUwYTk4YzMxfDllNTJkNjcyYTcxMTRhNjVhZDk2Mjg2YTM3MDNkOTZlfDB8MHw2MzgzNTA3OTEwMDkyMTk0NDZ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPak13TlRWbU5qWmpaamd4TXpRd056azVaV1F6WmpKaE16WTVOalkxWTJRM1FIUm9jbVZoWkM1Mk1pOXRaWE56WVdkbGN5OHhOams1TkRneU16QXdNVEV4fDUyMjllNzc1ZTkxYjRiZWZiMzc2MDhkYmUwYTk4YzMxfDE1ZTFkOGU4YTFjNTQ3YTg4ZTI4YTQxYjE0YjhjMmQ2&sdata=bTZGSVlWRGltV3JsN3FrUTNuYWo4L3lsVHN2em9GcTJLRHBpMWJRa3k2az0%3D&ovuser=9e52d672-a711-4a65-ad96-286a3703d96e%2CDonna.Vetrano%40kbr.com"
                    target="_blank"
                  >
                    Prompt Guide
                  </a>
                </p>
                <p className="mb-1">
                  <a
                    href="https://kbrcorp.sharepoint.com/sites/KBRaiN/SitePages/Dos-and-Don%27ts---Guidelines-for-KBR-Artificial-Intelligence-Network,-KBRaiN.aspx"
                    target="_blank"
                  >
                    Dos and Don'ts - Guidelines for KBR Artificial Intelligence
                    Network
                  </a>
                </p>
                <p className="mb-1">
                  <a
                    href="https://kbrcorp-my.sharepoint.com/personal/donna_vetrano_kbr_com/Lists/AI%20Glossary/AllItems.aspx?env=WebViewList&origin=createListFromTemplate&xsdata=MDV8MDF8fDMxMTVhNmZhZGZjYTRiNmViMzc2MDhkYmUwYTk4YzMxfDllNTJkNjcyYTcxMTRhNjVhZDk2Mjg2YTM3MDNkOTZlfDB8MHw2MzgzNTA3OTEwMDkyMTk0NDZ8VW5rbm93bnxWR1ZoYlhOVFpXTjFjbWwwZVZObGNuWnBZMlY4ZXlKV0lqb2lNQzR3TGpBd01EQWlMQ0pRSWpvaVYybHVNeklpTENKQlRpSTZJazkwYUdWeUlpd2lWMVFpT2pFeGZRPT18MXxMMk5vWVhSekx6RTVPak13TlRWbU5qWmpaamd4TXpRd056azVaV1F6WmpKaE16WTVOalkxWTJRM1FIUm9jbVZoWkM1Mk1pOXRaWE56WVdkbGN5OHhOams1TkRneU16QXdNVEV4fDUyMjllNzc1ZTkxYjRiZWZiMzc2MDhkYmUwYTk4YzMxfDE1ZTFkOGU4YTFjNTQ3YTg4ZTI4YTQxYjE0YjhjMmQ2&sdata=M3l4T0gwRk5qSzg5NjVnQm1xbUc4ZWZGdDNtYVFHSWVxdVpnUG9hT3Mvbz0%3D&ovuser=9e52d672-a711-4a65-ad96-286a3703d96e%2CDonna.Vetrano%40kbr.com"
                    target="_blank"
                  >
                    Artificial Intelligence (AI) Glossary
                  </a>
                </p>
                <p className="mb-1">
                  <a
                    href="https://sway.office.com/LebPm3RvM8FxRD3w?play"
                    target="_blank"
                  >
                    Benefits
                  </a>
                </p>
              </div>
            </Stack>
            <Stack>
              <div>
                <span className="mt-0">Feedback & Support</span>
                <p className="mb-1">
                  <a href="https://kbrsupport.service-now.com/kbr_sp?id=inc_cat_item&sys_id=aa347e3bdbe326406037f1fcbf961907" target="_blank">
                    DASH Incident Ticket
                  </a>
                </p>
                <p className="mb-1">
                  <a href="mailto:KBRAIN@kbr.com">KBRaiN&#8480; email</a>
                </p>
              </div>
            </Stack>
          </div> */}
        </MainPanel>
        {/* <SidePanel className="d-flex flex-column">
          <TitleBlock className="d-flex align-items-center">
            <BGIconWrapper className="mr-3">
              <ArticleIcon />
            </BGIconWrapper>
            <span className="flex-grow-1"> Proposals</span>
            <TextUnderlinedButton>All Proposals</TextUnderlinedButton>
          </TitleBlock>
          <div className="d-flex flex-grow-1 m-3 ">
          <Stack>
              <div>
                <BrainIcon />
                <span>New Proposal</span>
              </div>
            </Stack>
          </div>
        
        </SidePanel> */}
      </PanelWrapper>
      <div></div>
    </IntroWrapper>
  );
};
