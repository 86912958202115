import { ACTION_TYPES } from "@app/app/actions/types";
import {
  CHAT_MODE,
  chatContentType,
  initialStateType,
} from "@app/app/types";
import { createDerivedFromId } from "@app/components/proposal/requirement-review";
import { doc } from "prettier";
export const initialState: initialStateType = {
  auth: {
    authToken: null,
    user: null,
  },
  isCurrentChatQueryProcessing: false,
  isLoadingDatasetFiles: false,
  currentFocusChatConfig: null,
  currentChatQuery: null,
  currentChat: null,
  currentChatMode: CHAT_MODE.KBR,
  chats: [],
  chatMessages: [],
  processingMessage: "",
  hideLeftPanel: false,
  currentProposal: {
    id: null,
    status: null,
    error: null,
    isProcessing: false,
    isSendingResponseMessage: false,
    isSendingRequirementMessage: false,
    isSendingExcluded: false,
    isSendingFeedback: false,
  },
  feature: {
    proposal: true,
  },
  currentSelectedSource: {
    skill: null,
    mode: null,
    datasets: [],
    files: [],
    focus: null,
  },
  chatConfigurations: [],
  generateResponseInProgress: JSON.parse(sessionStorage.getItem('generateResponseInProgress') || '{}'),
  loadingChatConfigurations: false,
  isFocusedChat: false,
  isFinalizeProcessing: sessionStorage.getItem('isFinalizeProcessing') === 'true',
  fileUpdate: {},
  signalR: {
    subscriptionId: null,
    signalRConnection: null,
    signalRInterval: null,
  }

};
export const chatReducer = (state: initialStateType, action) => {
  console.log(action)
  switch (action.type) {
    case ACTION_TYPES.SET_AUTH:
      return {
        ...state,
        auth: action.payload,
      };

    case ACTION_TYPES.SET_SELECTED_DATASET_FILES:
      if (action.payload.files && action.payload.files[0]?.datasource) {
        const datasetId = action.payload.files[0].datasource;
        const { datasets } = state.currentSelectedSource;
        const dataset = datasets?.find(
          (d) => d.id == datasetId || d.name == datasetId
        ) || {};

        const newCurrentSelectedSourceUpdatedWithFiles = {
          ...state.currentSelectedSource,
          datasets: [
            ...(state.currentSelectedSource.datasets?.map((dataset) => {
              const match =
                dataset.id == datasetId || dataset.name == datasetId;
              return {
                ...dataset,
                files: match ? action.payload.files : dataset.files || [],
              };
            })||[]),
          ],
        };
        return {
          ...state,
          currentSelectedSource: newCurrentSelectedSourceUpdatedWithFiles,
        };
      }
      return state;

    case ACTION_TYPES.SET_SELECTED_DATASOURCES:
      const newCurrentSelectedSource = {
        ...state.currentSelectedSource,
        ...action.payload,
      };
      if (state.currentChat && parseInt(state.currentChat.id)) {
        const localStorageChatConfigs = JSON.parse(
          window.localStorage.getItem("chatConfigs") || "[]"
        );
        const existingOthers = localStorageChatConfigs.filter(
          (c) => parseInt(c.chatId) !== parseInt(state.currentChat.id)
        );
        const chatConfigs = [
          ...existingOthers,
          {
            chatId: parseInt(state.currentChat.id),
            currentSelectedSource: newCurrentSelectedSource,
          },
        ];
        window.localStorage.setItem("chatConfigs", JSON.stringify(chatConfigs));
      }
      return {
        ...state,
        currentSelectedSource: newCurrentSelectedSource || {},
      };

    case ACTION_TYPES.SET_CURRENT_FOCUS_CHAT_CONFIG:
      window.localStorage.setItem('currentFocusChatConfig', action.payload)
      return {
        ...state,
        currentSelectedSource: {
          ...state.currentSelectedSource,
          focus: action.payload,
        },
        currentFocusChatConfig: action.payload,
      };
    case ACTION_TYPES.SET_FOCUS_CHAT_CONFIGURATIONS:
      return {
        ...state,
        chatConfigurations: action.payload
      }
    case ACTION_TYPES.SET_LOADING_FOCUS_CHAT_CONFIGURATIONS_STATUS:
      return {
        ...state,
        loadingChatConfigurations: action.payload
      }
    case ACTION_TYPES.TOGGLE_LEFT_PANEL:
      return {
        ...state,
        hideLeftPanel: action.payload,
      };
    case ACTION_TYPES.SET_LOADING_DATASET_FILES:
      return {
        ...state,
        isLoadingDatasetFiles: action.payload,
      };
    case ACTION_TYPES.SET_CHAT_QUERY_PROCESSING:
      return {
        ...state,
        isCurrentChatQueryProcessing: action.payload,
      };
    case ACTION_TYPES.SET_CHAT_QUERY:
      return {
        ...state,
        currentChatQuery: action.payload.query,
      };
    case ACTION_TYPES.SET_CHAT_PROCESSING_QUERY:
      return {
        ...state,
        processingMessage: action.payload.query,
      };

    case ACTION_TYPES.SET_CURRENT_CHAT: {
      return {
        ...state,
        currentChat: {
          ...state.currentChat,
          ...action.payload,
        },
      };
    }
    case ACTION_TYPES.SET_CURRENT_CHAT_ID: {
      return {
        ...state,
        currentChat: {
          id: action.payload.id,
        }
      };
    }
    case ACTION_TYPES.SET_CURRENT_CHAT_MODE: {
      return {
        ...state,
        currentChatMode: action.payload.mode,
      };
    }
    case ACTION_TYPES.UPDATE_CHAT_LIST: {
      return {
        ...state,
        chats: action.payload,
      };
    }

    case ACTION_TYPES.UPDATE_CHAT_MESSAGES: {
      const oldChatMessages =
        state.chatMessages.find(
          (m) =>
            m.timestamp_id.toString() == action.payload.timestamp_id.toString()
        ) || {};
      return {
        ...state,
        chats: state.chats.map((chat) => {
          if (
            chat.timestamp_id.toString() !==
            action.payload.timestamp_id.toString()
          ) {
            return chat;
          }
          return {
            ...chat,
            ...action.payload,
          };
        }),
        chatMessages: [
          ...state.chatMessages.filter(
            (m) =>
              m.timestamp_id.toString() !==
              action.payload.timestamp_id.toString()
          ),
          { ...oldChatMessages, ...action.payload },
        ],
      };
    }
    case ACTION_TYPES.UPDATING_FEEDBACK: {
      return {
        ...state,
        chats: state.chats.map((chat: chatContentType) => {
          if (
            chat.timestamp_id.toString() !== action.payload.chatId.toString()
          ) {
            return chat;
          }
          return {
            ...chat,
            messages: chat.messages?.map((m, idx) => {
              if (idx === action.payload.index) {
                return {
                  ...m,
                  feedback: action.payload.feedback,
                  feedbackProcessing: action.payload.processing,
                };
              } else {
                return m;
              }
            }),
          };
        }),
        chatMessages: state.chatMessages.map((chat) => {
          if (
            chat.timestamp_id.toString() == action.payload.chatId.toString()
          ) {
            return {
              ...chat,
              messages: chat.messages.map((m, idx) => {
                if (idx === action.payload.index) {
                  return {
                    ...m,
                    feedback: action.payload.feedback,
                    feedbackProcessing: action.payload.processing,
                  };
                } else {
                  return m;
                }
              }),
            };
          } else {
            return chat;
          }
        }),
      };
    }
    case ACTION_TYPES.SET_CURRENT_PROPOSAL: {
      const { isFinalizeProcessing } = action.payload;
      if (isFinalizeProcessing !== undefined) {
        sessionStorage.setItem('isFinalizeProcessing', isFinalizeProcessing.toString());
      }
      return {
        ...state,
        currentProposal: {
          ...state.currentProposal,
          ...action.payload,
        },
      };
    }
      case ACTION_TYPES.TOGGLE_FOCUSED_CHAT:
        return {
          ...state,
          isFocusedChat: action.payload
        };
      case ACTION_TYPES.SET_GENERATE_RESPONSE_TOGGLE:
        sessionStorage.setItem('generateResponseInProgress', JSON.stringify({
          ...state.generateResponseInProgress,
          ...action.payload
        }));
        return {
          ...state,
          generateResponseInProgress: {
            ...state.generateResponseInProgress,
            ...action.payload
          }
        };
    case ACTION_TYPES.WEBSOCKET_UNSUBSCRIBE:
      return {
        ...state, // Do nothing here yet
      };

    case ACTION_TYPES.WEBSOCKET_SUBSCRIBE:
      return {
        ...state, // Do nothing here yet
      };
    case ACTION_TYPES.BROADCAST_UPSERT_PROPOSAL: 
    console.log("BROADCAST_UPSERT", action.payload);
   return {
    ...state,
    currentProposal: {
      ...state.currentProposal,
      isProcessing: !(action.payload.status === "completed"),
    },
  }
    case ACTION_TYPES.BROADCAST_GENERATE_RESPONSE:
      console.log("BROADCAST_GENERATE_RESPONSE", action.payload);
      const derivedFromId = createDerivedFromId({ pdfName:action.payload.file_name, page:action.payload.page, paragraph: action.payload.paragraph}, action.payload.requirement.toString());
      if (action.payload.state === "started") {  
        const newState = {...state.generateResponseInProgress};
        newState[derivedFromId] =  {
          isGenerating: true,
          response: {
            summary: 'Generating response...',
            citations: []
          }
        }
        sessionStorage.setItem('generateResponseInProgress', JSON.stringify(newState));

        return {
          ...state,
          generateResponseInProgress: {
            ...state.generateResponseInProgress,
            ...action.payload
          }
        };

      } else if (action.payload.state == "complete") {
        console.log("Complete state:")
        const completedState = {...state.generateResponseInProgress}
        completedState[derivedFromId] = {
          isGenerating: false,
          response: {
            summary: action.payload.response.summary,
            citations: action.payload.response.citations
          }
        }
        sessionStorage.setItem('generateResponseInProgress', JSON.stringify(completedState));
      }

      const sessionStorageState = JSON.parse(sessionStorage.getItem('generateResponseInProgress') || '{}');
      console.log(sessionStorageState)
      return {
        ...state,
        generateResponseInProgress: sessionStorageState
      }
    case ACTION_TYPES.BROADCAST_CHAT:
      console.log("BROADCAST_CHAT", action.payload);
      return {
        ...state,
        chats: [
          ...state.chats,
          {
            proposal_id: action.payload.proposal_id,
            chat_type: action.payload.chat_type,
            messages: action.payload.messages,
            ...action.payload.requirement_reference,
          },
        ],
      };
    case ACTION_TYPES.BROADCAST_FINALIZE_PROPOSAL:
      console.log("BROADCAST_FINALIZE_PROPOSAL", action.payload);
      if (action.payload.state === "start") {
        return {
          ...state,
          isFinalizeProcessing: true,
          docxUrl: null
        }
      }
      return {
        ...state,
        isFinalizeProcessing: false,
        docxUrl: action.payload.docx_url
      }
      

    case ACTION_TYPES.BROADCAST_FILE_STATUS:
      console.log("BROADCAST_FILE_STATUS", action.payload);
      return { 
        ...state,
        fileUpdate: {
          ...state.fileUpdate,
          [action.payload.file_name]: {
            progress: action.payload.progress,
            status: action.payload.status,
            url: action.payload.url,
            isWinTheme: false
          }
        }
      }
    case ACTION_TYPES.SET_SIGNALR_STATE:
      return {
        ...state,
        signalR: {
          ...state.signalR,
          ...action.payload
        }
      }
    case ACTION_TYPES.BROADCAST_PROPOSAL_ERROR:
      console.log("BROADCAST_ERROR", action.payload);
      return {
        ...state,
      };
    default: {
      console.error("Unknown action: ", action);
      return state;
    }
  }
};
