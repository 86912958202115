import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ReactQuill from "react-quill";
import {
  queryProposal,
  queryProposalStatus,
  queryMembersList,
  queryAllowedGroups
} from "@app/app/queries";
import { useFileList } from "@app/app/mutations";
import * as RFP_API from "@app/api/rfp/endpoints";
import {
  useUpdateProposal,
  useChatResponse,
  useChatRequirement,
  useGenerateResponses,
  useFinalizeProposal,
  useRequirementExclude,
  useRequirementFeedback,
} from "@app/app/mutations";
import { setCurrentSelectedDataSources } from "@app/app/actions";

import { ActionIconButton } from "@app/components/chat-content/styles";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import {
  faThumbsUp,
  faThumbsDown,
  faPaperPlane,
  faCommentDots,
} from "@fortawesome/free-regular-svg-icons";

import {
  faArrowUpRightFromSquare,
  faPlus,
  faList,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCircleExclamation,
  faPencil,
  faSpinner,
  faComment,
  faRobot,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { CheckBox } from "devextreme-react/check-box";
import Button from "devextreme-react/button";
import List from "devextreme-react/list";
import { Tooltip, Position } from "devextreme-react/tooltip";
import { LoadIndicator } from "devextreme-react/load-indicator";
import styled from "styled-components";
import { PlainButton } from "@app/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputWrapper, BottomArea } from "@app/components/chat-input/styles";
import {
  SummaryCard,
  Wrapper,
  MessageBoard,
  ChatQueryMessage,
  RightFixedPanel,
  LinkButton,
} from "./styles";
import { chatRequestPayloadType, generateResponsesRequestPayload } from "@app/api/types";
import { mockup_requirements } from "@app/components/proposal/fixtures/proposals";
import Avatar from "@app/components/avatar/user";
import AvatarAssisant from "@app/components/avatar/assistant";
import { MESSAGE_ROLE } from "@app/api/types";
import { scrollToBottom } from "@app/utils";
import { queryProposalDatasetsList } from "@app/app/queries";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { ContentLoaderWrapper } from "@app/styles";
import {
  DataGrid,
  Column,
  Selection,
  Scrolling,
  SearchPanel,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import SelectBox, { SelectBoxTypes } from "devextreme-react/select-box";
import Badge from "react-bootstrap/Badge";
import { internals } from "@azure/msal-browser";
import { json, useLocation } from "react-router-dom";
import { MentionDropdownInverse } from "../styles";
import { on } from "devextreme/events";
import { Logo } from "@app/styles/logo";
import { Dict } from "styled-components/dist/types";
import { EDIT_MODE } from "../types";

const avatars = [
  { id: 1, name: "KBRaiN", email: "kbrain@example.com" },
  { id: 2, name: "Avatar 1", email: "avatar1@example.com" },
  { id: 3, name: "Avatar 2", email: "avatar2@example.com" },
  { id: 4, name: "Avatar 3", email: "avatar3@example.com" },
];

const RequirementReview = () => {
  const myRefs = useRef([]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const quillRef = React.createRef();
  const state = useAppContext();
  const dispatch = useAppDispatchContext();
  const {
    currentProposal: {
      id: currentProposalId,
      status: currentProposalStatus,
      isSendingRequirementMessage,
      isSendingResponseMessage,
      isSendingExcluded,
      isSendingFeedback,
      isFinalizeProcessing,
      docxUrl
    },
    currentSelectedSource,
    isLoadingDatasetFiles,
    generateResponseInProgress,
  } = useAppContext();
  const uncheckedLabel = { "aria-label": "Unchecked" };
  const [showDatasetModal, setShowDatasetModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [collaborate, setCollaborate] = useState<string | undefined>("");
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilteringModal, setShowFilteringModal] = useState(false);
  const [currentSelectedDataset, setCurrentSelectedDataset] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState([]);
  const [selectedFilesInModal, setSelectedFilesInModal] = useState<{
    id: string;
    files: [];
  }>(null);
  const [currentSummary, setCurreySummary] = useState<{
    filename: string;
    summary: string;
    tags: string[];
  }>();
  const [selectedFiles, setSelectedFiles] = useState({});
  const [showSourcesModal, setShowSourcesModal] = useState(false);
  const [runId, setRunId] = useState();
  const [files, setFiles] = useState([]);
  const chatResponse = useChatResponse(currentProposalId);
  const chatRequirement = useChatRequirement(currentProposalId);
  const generateResponse = useGenerateResponses();
  const [currentRequirementKeys, setCurrentRequirementKeys] =
    useState<chatRequestPayloadType>();
  const [currentRequirementIdx, setCurrentRequirementIdx] = useState(1);
  const [currentResponseId, setCurrentResponseId] = useState<null | string>(
    null
  );
  const [currentRequirementId, setCurrentRequirementId] = useState<
    null | string
  >(null);
  const updateProposal = useUpdateProposal(currentProposalId);
  const finalizeProposal = useFinalizeProposal();
  const requirementExclude = useRequirementExclude(currentProposalId);
  const requirementFeedback = useRequirementFeedback(currentProposalId);
  const { isLoading, error, data, refetch } = queryProposal(currentProposalId);
  const {
    isLoading: statusIsLoading,
    error: statusError,
    data: statusData,
    refetch: statusRefetch,
  } = queryProposalStatus(runId);
  const { isLoading: groupsIsLoading, data: allowedGroups, refetch: groupsRefetch } = queryAllowedGroups();
  const [proposalGroup, setProposalGroup] = useState(null);
  const [groupMap, setGroupMap] = useState({});
  useEffect(() => {
    if (allowedGroups?.groups) {
      const newGroupMap = allowedGroups.groups.reduce((acc, group) => {
        acc[group.id] = group.entra_group;
        return acc;
      }, {});

      setGroupMap(newGroupMap);
      console.log(newGroupMap);

      setProposalGroup(allowedGroups.groups[0].entra_group);
    }
  }, [allowedGroups]);
  useEffect(() => {
    groupsRefetch();
  }, []);

  const { data: membersData, isLoading: membersLoading, error: membersError } = queryMembersList({
    group_id: proposalGroup,
    continuation_token: null
  });
  const MESSAGE_GENERATE_RESPONSE_IN_PROCESS = "InProgress";
  const MESSAGE_GENERATE_RESPONSE_SUCCEED = "Succeeded";
  const MESSAGE_GENERATE_RESPONSE_FAILED = "Failed";
  const RESPONSE_STATUS = {
    QUEUED: "QUEUED",
    SUCCESS: "success",
  };

  const responseInProgress =
    (statusData || {}).message === MESSAGE_GENERATE_RESPONSE_IN_PROCESS;
  const [finalizeInProgress, setFinalizeInProgress] = useState(false);
  const [currentChat, setCurrentChat] = useState(null);
  const { state: locationState } = useLocation();
  const { mode } = locationState || { mode: EDIT_MODE.EDIT };

  const modules = {
    toolbar: false,
  };

  useEffect(() => {
    scrollToBottom(messagesEndRef);
  }, [currentRequirementIdx]);

  useEffect(() => {
    scrollToBottom(messagesEndRef, { behavior: "smooth" });
    if (!isSendingResponseMessage) {
      refetch();
    }
  }, [isSendingResponseMessage]);

  useEffect(() => {
    scrollToBottom(messagesEndRef, { behavior: "smooth" });
    if (!isSendingRequirementMessage) {
      refetch();
    }
  }, [isSendingRequirementMessage]);

  useEffect(() => {
    if (!isSendingExcluded) {
      refetch();
    }
  }, [isSendingExcluded]);

  useEffect(() => {
    if (!isSendingFeedback) {
      refetch();
    }
  }, [isSendingFeedback]);

  useEffect(() => {
    scrollToBottom(messagesEndRef, { behavior: "smooth" });
  }, [data]);

  useEffect(() => {
    if (currentProposalId) {
      refetch();
      setInterval(() => {
        if (runId) {
          statusRefetch();
        }
      }, 10000);
    }
  }, [runId]);

  const getFiles = () => {
    if (data?.files) {
      return Object.entries(data?.files)
        .filter((item: any) => !item[1].isWinTheme)
        .map((item) => ({
          name: item[0],
          //@ts-ignore
          ...item[1],
        }));
    } else {
      return [];
    }
  };
  type summaryType = {
    requirement_id: string;
    requirement_index: Number;
    original_text: string;
    summary: string;
    derivedFrom: {
      pdfName: string;
      page: number;
      paragraph: string;
    };
    messages: {
      user: string;
      content: string;
    }[];
    chats?: {
      query: string;
      message: string;
    }[];
    response?: {
      citations: string[];
      status: typeof RESPONSE_STATUS.SUCCESS | typeof RESPONSE_STATUS.QUEUED;
      summary: string;
      messages: {
        role: string;
        content: string;
      }[];
    };
    status?: "failed" | "processing";
    isExcluded?: boolean;
  };
  const summaries: summaryType[] = [];
  getFiles().map((item) => {
    const { name, requirements, status } = item;

    if (requirements) {
      let count = 0;
      Object.entries(requirements.page).forEach((page: any) => {
        Object.entries(page[1].paragraph).forEach((paragraph: any) => {
          paragraph[1].forEach((requirement: any, index: number) => {
            let requirement_id = (++count).toString();
            const derivedFromId = createDerivedFromId({ pdfName: name, page: page[0], paragraph: paragraph[0] }, index.toString())
            const summary = {
              requirement_id: requirement_id,
              requirement_index: index,
              summary: requirement.summary.toString(),
              original_text: requirement.original_text.toString(),
              messages: requirement.messages || [{ user: "KBRaiN", content: `Here is the requirement: ${requirement.original_text.toString()}` }, { user: "KBRaiN", content: `Here is the interpretation: ${requirement.summary.toString()}` }],
              response: {
                ...requirement.response,
                citations: requirement.response?.citations || [],
                messages: requirement.response?.messages || [{ user: "KBRaiN", content: `Here is the requirement: ${requirement.summary.toString()}.` }, { user: "KBRaiN", content: `Here is the interpretation: ${requirement.summary.toString()}` }, { user: "KBRaiN", content: `Here is the response to the requirement: ${(generateResponseInProgress[derivedFromId]?.response.summary || requirement.response?.summary)}` }],
              },
              derivedFrom: {
                pdfName: name,
                page: page[0],
                paragraph: paragraph[0],
              },
              isExcluded: requirement.isExcluded ? true : false,
              feedback: requirement.feedback,
            } as summaryType;
            summaries.push(summary);
          });
        });
      });
    } else {
      const summary = {
        requirement_id: null,
        summary: null,
        original_text: null,
        derivedFrom: {
          pdfName: name,
        },
        status,
      } as summaryType;
      summaries.push(summary);
    }
  });
  const updateExclude = (file, page, paragraph, requirement, value) => {
    requirementExclude.mutate({
      proposal_id: currentProposalId,
      filename: file,
      page,
      paragraph,
      requirement,
      isExcluded: value,
    });
  };
  const onClickTrackFeedback = (e: React.MouseEvent<HTMLElement>) => {
    const { file, page, paragraph, requirement, feedback, is_response = false } =
      e.currentTarget.dataset;
    requirementFeedback.mutate({
      proposal_id: currentProposalId,
      filename: file,
      page: parseInt(page),
      paragraph: parseInt(paragraph),
      requirement: parseInt(requirement),
      feedback: feedback == "-1" ? -1 : 1,
      is_response: Boolean(is_response)
    });
  };
  const handleExcludeRequirement = (e) => {
    const {
      component: { _props },
      value,
    } = e;
    const file = _props["data-file"];
    const page = _props["data-page"];
    const paragraph = _props["data-paragraph"];
    const requirement = _props["data-requirement"];
    updateExclude(file, page, paragraph, requirement, value);
  };
  const setReplayBoxEvent = (data) => {
    const index = data.requirement_id;
    setCurrentResponseId(data.isResponse ? data.requirement_id : null)
    setCurrentRequirementId(data.requirement_id)
    setCurrentRequirementIdx(index)
    const values = {
      proposal_id: currentProposalId,
      filename: data.derivedFrom.pdfName,
      page: data.derivedFrom.page,
      paragraph: data.derivedFrom.paragraph,
      requirement: data.requirement_index,
      requirement_id: data.requirement_id,
      prompt: data.summary,
      editedSummary: data.summary,
      editedResponse: data.response?.summary,
    }
    setCurrentRequirementKeys(values)
    return values
  };
  const isEditRequirement = (data) => {
    return currentRequirementKeys && data.derivedFrom.page == currentRequirementKeys.page &&
      data.derivedFrom.paragraph == currentRequirementKeys.paragraph &&
      (data.requirement_id) == currentRequirementKeys.requirement_id && currentRequirementKeys.isEditing
  }
  const isEditResponse = (data) => {
    return currentRequirementKeys && data.derivedFrom.page == currentRequirementKeys.page &&
      data.derivedFrom.paragraph == currentRequirementKeys.paragraph &&
      (data.requirement_id) == currentRequirementKeys.requirement_id && currentRequirementKeys.isEditingResponse
  }
  const sendManualEdit = (modifiedText, isResponse = false, setValues = null) => {
    const values = setValues || { ...currentRequirementKeys }
    isResponse ?
      chatResponse.mutate({
        ...values,
        proposal_id: currentProposalId,
        prompt: modifiedText,
        editedSummary: !isResponse ? modifiedText : '',
        editedResponse: isResponse ? modifiedText : ''
      })
      : chatRequirement.mutate({
        ...values,
        proposal_id: currentProposalId,
        prompt: modifiedText,
        editedSummary: !isResponse ? modifiedText : '',
        editedResponse: isResponse ? modifiedText : ''
      });
    setCurrentRequirementKeys({
      ...currentRequirementKeys,
      isEditing: false,
      isEditingResponse: false
    })
  }
  const renderSummary = (data, index) => {
    let derivedFromId = null
    if (!data.status) {
      derivedFromId = createDerivedFromId(data.derivedFrom, data.requirement_index.toString())
      return (
        <SummaryCard
          className={`card ${currentRequirementId === data.requirement_id ? "active" : ""
            }`}
        >
          {/* <div className="card-header"></div> */}
          <div className="card-body">
            <div className="d-flex">
              <div className="d-flex flex-grow-1 justify-content-left"><h5 className="card-title">Requirement {data.requirement_id}</h5></div>
              <div className="d-flex justify-content-right align-items-center">
                {!isEditRequirement(data) && <ActionIconButton
                  icon={faPencil}
                  style={{ fontSize: "14px" }}
                  className={"ml-1"}
                  title="Edit"
                  onClick={async e => {
                    const values = await setReplayBoxEvent({ ...data, isResponse: false })
                    setCurrentRequirementKeys({
                      ...values,
                      isEditing: true,
                      isEditingResponse: false
                    })
                  }}
                />}
                <ActionIconButton
                  icon={faComment}
                  style={{ fontSize: "14px" }}
                  className={"ml-1"}
                  title="Comment"
                  onClick={(e) => setReplayBoxEvent({
                    proposal_id: currentProposalId,
                    derivedFrom: { ...data.derivedFrom },
                    requirement_index: data.requirement_index,
                    requirement_id: data.requirement_id,
                    summary: data.summary,
                    response: { ...data.response },
                    isResponse: false
                  })}
                />
              </div>
            </div>
            <div className="d-flex flex-grow-1">
              {!isEditRequirement(data) && <p className="card-text">{data.summary}</p>}
              {isEditRequirement(data) && <p className="w-100 d-flex flex-column justify-content-end">
                <form onSubmit={e => { e.preventDefault(); sendManualEdit(`EDIT: ${e.target[0].value}`) }}>
                  <textarea className="w-100" style={{ minHeight: '100px' }}
                    defaultValue={currentRequirementKeys.editedSummary}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  ></textarea>
                  <div className="text-right"><Button useSubmitBehavior={true} className="mt-2">Save</Button></div>
                </form>
              </p>
              }
            </div>
            <p className="d-flex justify-content-between">
              <a
                id={derivedFromId}
                className="link-opacity-100 underline"
                href="#"
              >
                Derived from{" "}
                <span style={{ fontWeight: "bold" }}>
                  {data.derivedFrom.pdfName}
                </span>
                , page{" "}
                <span style={{ fontWeight: "bold" }}>
                  {data.derivedFrom.page}
                </span>
                , paragraph{" "}
                <span style={{ fontWeight: "bold" }}>
                  {data.derivedFrom.paragraph}
                </span>
              </a>
              <Tooltip
                target={`#${derivedFromId}`}
                showEvent="mouseenter"
                hideEvent="mouseleave"
                position={"right"}
                hideOnOutsideClick={true}
              >
                <div className="derivedFrom">{data.original_text}</div>
              </Tooltip>

              <div className="d-flex mt-2 user-actions">
                <div
                  onClick={async e => {
                    const values = await setReplayBoxEvent({ ...data, isResponse: false })
                    setCurrentRequirementKeys({
                      ...values,
                      isEditing: false,
                      isEditingResponse: false
                    });
                    sendManualEdit(`REQUIREMENT FEEDBACK: +1`, false, values)
                  }}
                  className={`feedbackBtn  align-items-center d-flex mr-2`}
                >
                  <ActionIconButton icon={faThumbsUp} />
                </div>
                <div
                  onClick={async e => {
                    const values = await setReplayBoxEvent({ ...data, isResponse: false })
                    setCurrentRequirementKeys({
                      ...values,
                      isEditing: false,
                      isEditingResponse: false
                    });
                    sendManualEdit(`REQUIREMENT FEEDBACK: -1`, false, values)
                  }}
                  className={`feedbackBtn  align-items-center d-flex`}
                >
                  <ActionIconButton icon={faThumbsDown} />
                </div>
              </div>
            </p>
            <div className="d-flex justify-content-end">
              <label className="mr-2">Excluded?</label>
              <CheckBox
                key={
                  data.derivedFrom.pdfName +
                  data.derivedFrom.page +
                  data.derivedFrom.paragraph +
                  data.requirement_id
                }
                data-file={data.derivedFrom.pdfName}
                data-page={parseInt(data.derivedFrom.page)}
                data-paragraph={parseInt(data.derivedFrom.paragraph)}
                data-requirement={parseInt(data.requirement_id) - 1}
                name={"isExcluded"}
                className="mb-3"
                elementAttr={uncheckedLabel}
                value={data.isExcluded ? true : false}
                onValueChanged={handleExcludeRequirement}
              />
            </div>
            <p className="d-flex">
              <div className="d-flex flex-grow-1 justify-content-left">
                <h5 className="card-title">Response {data.requirement_id}</h5>
              </div>
              <div className="d-flex justify-content-right align-items-center">
                {data.response && !(generateResponseInProgress[derivedFromId]?.isGenerating) && (
                  <>
                    <ActionIconButton
                      className="ml-2"
                      icon={faPencil}
                      style={{ fontSize: "14px" }}
                      onClick={async e => {
                        const values = await setReplayBoxEvent({ ...data, isResponse: true })
                        setCurrentRequirementKeys({
                          ...values,
                          isEditing: false,
                          isEditingResponse: true
                        })
                      }}
                    />
                    {(data.response?.summary || generateResponseInProgress[derivedFromId]?.response.summary) &&
                      <ActionIconButton
                        icon={faComment}
                        style={{ fontSize: "14px" }}
                        className={"ml-1"}
                        title="Comment"
                        onClick={(e) => setReplayBoxEvent({
                          proposal_id: currentProposalId,
                          derivedFrom: { ...data.derivedFrom },
                          requirement_index: data.requirement_index,
                          requirement_id: data.requirement_id,
                          summary: data.summary,
                          response: { ...data.response },
                          isResponse: true
                        })}
                      />
                    }
                    {generateResponseInProgress[derivedFromId] && generateResponseInProgress[derivedFromId].isGenerating ? (
                      <LoadIndicator width={54} height={14} className="text-white" />
                    ) : <ActionIconButton
                      icon={faRobot}
                      style={{ fontSize: "14px" }}
                      className={"ml-1"}
                      title="Generate Response"
                      onClick={() => {
                        startGeneratingResponse(data, derivedFromId)
                      }}
                    />}
                  </>
                )}
              </div>
            </p>
            <p className="d-flex flex-grow-1">
              <p className="card-text w-100">
                {(generateResponseInProgress[derivedFromId] || data.response) && !(generateResponseInProgress[derivedFromId]?.isGenerating)
                  ?
                  <div className="w-100">
                    {!isEditResponse(data) && (generateResponseInProgress[derivedFromId]?.response.summary || data.response.summary)}
                    {isEditResponse(data) &&

                      <p className="w-100 flex-column justify-content-end">
                        <form onSubmit={e => {
                          e.preventDefault();
                          sendManualEdit(`EDIT: ${e.target[0].value}`, true)
                        }}>
                          <textarea className="w-100 response" style={{ minHeight: '100px' }}
                            defaultValue={currentRequirementKeys.editedResponse}
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          ></textarea>
                          <div className="text-right"><Button useSubmitBehavior={true} className="mt-2">Save</Button></div>
                        </form>
                      </p>}
                  </div>
                  :
                  <><span>"Response generating..."</span><LoadIndicator width={54} height={14} className="text-white" /></>
                }
              </p>
            </p>
            <p className="d-flex">
              <div>
                {!(generateResponseInProgress[derivedFromId]?.isGenerating) && (generateResponseInProgress[derivedFromId]?.response?.citations?.length > 0 || data.response?.citations.length > 0) ? <h6 className="card-title">Citations</h6> : ''}
                {data.response
                  && !(generateResponseInProgress[derivedFromId]?.isGenerating)
                  && (generateResponseInProgress[derivedFromId]?.response.citations || data.response.citations).map((citation, citationIndex) => (
                    <div key={index}>
                      <Tooltip
                        target={`#user${index.toString()}${citationIndex.toString()}`}
                        showEvent="mouseenter"
                        hideEvent="mouseleave"
                        hideOnOutsideClick={false}
                        position={"right"}
                      >
                        {citation.text}
                      </Tooltip>
                      <button
                        id={`user${index.toString()}${citationIndex.toString()}`}
                        type="button"
                        key={index}
                        className="d-block btn btn-secondary btn-sm mb-1 text-left"
                        data-toggle="tooltip"
                        title="tooltip"
                        onClick={(e) => {
                          citation.url
                            ? window.open(citation.url)
                            : "";
                        }}
                      >
                        {`[${citation.id}] ${citation.filename}, ${citation.page ? 'Page ' + citation.page + ', ' : ''} ${citation.paragraph ? 'Paragraph ' + citation.paragraph : 'Section ' + citation.section}`}
                      </button>
                    </div>
                  ))}
              </div>
            </p>
            {(generateResponseInProgress[derivedFromId]?.response || data.response?.summary) && !(generateResponseInProgress[derivedFromId]?.isGenerating) ?
              <p className="d-flex justify-content-end">
                <div className="d-flex user-actions">
                  <div
                    onClick={async e => {
                      const values = await setReplayBoxEvent({ ...data, isResponse: true })
                      setCurrentRequirementKeys({
                        ...values,
                        isEditing: false,
                        isEditingResponse: false
                      });
                      sendManualEdit(`RESPONSE FEEDBACK: +1`, true, values)
                    }}
                    className={`feedbackBtn  align-items-center d-flex mr-2`}
                  >
                    <ActionIconButton icon={faThumbsUp} />
                  </div>
                  <div
                    onClick={async e => {
                      const values = await setReplayBoxEvent({ ...data, isResponse: true })
                      setCurrentRequirementKeys({
                        ...values,
                        isEditing: false,
                        isEditingResponse: false
                      });
                      sendManualEdit(`RESPONSE FEEDBACK: -1`, true, values)
                    }}
                    className={`feedbackBtn  align-items-center d-flex`}
                  >
                    <ActionIconButton icon={faThumbsDown} />
                  </div>
                </div>
              </p>
              : ''}
          </div>
        </SummaryCard>
      );
    } else {
      if (data.status == "failed") {
        return (
          <p className="d-flex align-items-center">
            <span className="mr-3">{data.derivedFrom.pdfName}</span>
            <ActionIconButton icon={faCircleExclamation} />
          </p>
        );
      } else if (data.status == "processing") {
        return (
          <p className="d-flex align-items-center">
            <span className="mr-3">{data.derivedFrom.pdfName}</span>
            <ActionIconButton icon={faSpinner} spin />
          </p>
        );
      } else {
        return (
          <p>
            {data.derivedFrom.pdfName} - {data.status}
          </p>
        );
      }
    }
  };

  const onKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      e.stopPropagation();
      onSendMessage();
    }
  };
  const handleSendingMessage = (e: React.MouseEvent<HTMLElement>) => {
    onSendMessage();
  };
  const downloadFile = (e: React.MouseEvent<HTMLElement>) => {
    const { url } = e.currentTarget.dataset;
    window.open(url);
  };
  const onSendMessage = () => {
    //@ts-ignore
    const prompt = quillRef.current
      //@ts-ignore
      .getEditor()
      .getText()
      .replace(/(\r\n|\n|\r)/gm, "");

    //send message
    if (currentResponseId) {
      const values = { ...currentRequirementKeys }
      delete values.editedResponse
      chatResponse.mutate({
        ...values,
        proposal_id: currentProposalId,
        prompt,
      });
    } else if (currentRequirementIdx) {
      const values = { ...currentRequirementKeys }
      delete values.editedSummary
      chatRequirement.mutate({
        ...values,
        proposal_id: currentProposalId,
        prompt,
      });

      //   sendMessage.mutate({ id, message: prompt });
    }
    // setMessage(prompt);
    setCollaborate("");
  };
  const submitProposal = () => {
    updateProposal.mutate({ status: "requirement-review" });
    finalizeProposal.mutate({ proposal_id: currentProposalId });
  };
  //set focust to end
  useEffect(() => {
    //@ts-ignore
    // const quill = quillRef.current.getEditor();
    // quill.focus();
    // quill.setSelection(quill.getLength(), 0);
  }, [collaborate]);
  useEffect(() => {
    const data = getSummary();
    if (data && data[0]) {
      setCurrentRequirementId(data[0]?.requirement_id);
    }
  }, []);

  console.log("ddd selected currentSelectedSource", currentSelectedSource);
  useEffect(() => {
    const all = {};
    if (currentSelectedSource.files && currentSelectedSource.files.length > 0) {
      currentSelectedSource.files.forEach((f) => {
        if (!all[f.datasource]) {
          all[f.datasource] = [];
        }
        all[f.datasource].push(f);
      });
      setSelectedFiles(all);
    }
  }, [currentSelectedSource]);

  const startGeneratingResponse = (data, derivedFromId) => {
    const values = {
      proposal_id: currentProposalId,
      filename: data.derivedFrom.pdfName,
      page: data.derivedFrom.page,
      paragraph: data.derivedFrom.paragraph,
      requirement: data.requirement_index,
      derived_from_id: derivedFromId,
    }
    //@ts-ignore
    generateResponse.mutate(values);
  };
  const getSummary = () => {
    // if (summaries.length === 0) {
    //   return mockup_requirements();
    // }
    return summaries || [];
  };
  const currentRequirement = getSummary()[currentRequirementIdx - 1];

  const cardContent = () => {
    const currentResponse = currentRequirement.response;
    const content = currentResponseId ? currentResponse : currentRequirement;

    return (
      <>
        {content?.messages?.map((message, idx) => {
          return (
            <ChatQueryMessage key={idx} className="d-flex">
              <p className="query">
                {message.user === 'KBRaiN' ? (
                  <AvatarAssisant className="mr-3" />
                ) : (
                  <Avatar email={message.user} />
                )}
              </p>
              <div className="flex-grow-1">
                <p>{message.content}</p>
              </div>
            </ChatQueryMessage>
          );
        })}
        {(isSendingResponseMessage || isSendingRequirementMessage) && (
          <ChatQueryMessage className="d-flex">
            <AvatarAssisant className="mr-3" />
            <div className="flex-grow-1">
              <ActionIconButton icon={faCommentDots} className="mt-1 blink" />
            </div>
          </ChatQueryMessage>
        )}
        <div ref={messagesEndRef}> </div>
      </>
    );
  };

  const {
    isLoading: datasetsLoading,
    error: datasetsError,
    data: datasetsList,
    refetch: datasetsRefetch,
  } = queryProposalDatasetsList({
    focus: "",
  });
  const fileList = useFileList(RFP_API.ENDPOINT_PROPOSAL_LIST_FILES);
  useEffect(() => {
    if (datasetsList) {
      if (datasetsList && Array.isArray(datasetsList)) {
        datasetsList.forEach((d) => {
          fileList.mutateAsync({
            dataset_id: d.id,
            max_items: 100,
          });
        });
        dispatch(
          setCurrentSelectedDataSources({
            datasets: datasetsList,
          })
        );
      }
    }
  }, [datasetsList]);

  //@ts-ignore
  const sortDatasetList = datasetsList
    ? [
      ...datasetsList
        .filter((d) => d.type == "OneDrive")
        .map((d) => ({ ...d, name: "OneDrive" })),
      ...datasetsList.filter((d) => d.type !== "OneDrive"),
    ].map((d) => ({
      ...d,
      displayName: `${d.name} - `,
    }))
    : [];

  const onDatasetSelectionChanged = async (e) => {
    await setCurrentSelectedDataset(e.value);
    // setSelectedDataset(e.component.getSelectedRowsData());
    // setCurrentStep(2);
  };

  const toggleFilteringModal = () => {
    setShowFilteringModal(true);
    // setShowDatasetModal(false);
    // setShowSourcesModal(false);
    setCurrentSelectedDataset(null);
    datasetsRefetch();
  };

  const hideFilteringModal = useCallback(() => {
    setShowFilteringModal(false);
  }, [setShowFilteringModal]);

  const onDatasetFilesSelectionChanged = (e) => {
    setSelectedFilesInModal({
      id: e.element.id,
      files: e.component.getSelectedRowsData(),
    });
    setSelectedFielsFromData({
      id: e.element.id,
      files: e.component.getSelectedRowsData(),
    });
  };

  const setValues = (e) => {
    const items = e.component.getDataSource()._items;
    // e.component.off("selectionChanged");
    if (items && items.length > 0 && currentSelectedSource.files) {
      const selectedfiles = currentSelectedSource.files
        .filter((f) => f.datasource == items[0].datasource)
        .map((f) => f.id);
      const selectedIndexes = items
        .map((i, index) => {
          return { index, selected: selectedfiles.includes(i.id) };
        })
        .filter((i) => i.selected)
        .map((i) => i.index);
      e.component.selectRowsByIndexes(selectedIndexes, true);
    }
  };

  const onFileDataGridCellClick = (e) => {
    if (e.rowType == "data" && e.column.dataField == "filename") {
      setCurreySummary(e.row.data);
      toggleSourceModal();
    }
  };

  const toggleSourceModal = useCallback(() => {
    setShowSourcesModal(true);
  }, [setShowSourcesModal]);

  const getNumberOfSelect = (datasource) => {
    const selectedfiles = currentSelectedSource.files
      ?.filter((f) => f.datasource == datasource.id)
      .map((f) => f.id);

    return selectedfiles?.length || 0;
  };

  const openFile = (e) => {
    const url = e.currentTarget.dataset.url;
    window.open(url, "_blank");
  };

  const onFileDetailClick = (data) => {
    setCurreySummary(data);
    toggleSourceModal();
  };

  const addFiltering = () => {
    const { id, files } = selectedFilesInModal || {};
    if (id && files) {
      setSelectedFielsFromData(selectedFilesInModal);
    }
    setShowFilteringModal(false);
  };

  const datasetModalButtonOptions = useMemo(
    () => ({
      width: 300,
      text: "Select Filter",
      type: "default",
      stylingMode: "contained",
      onClick: addFiltering,
    }),
    [addFiltering]
  );

  const setSelectedFielsFromData = async ({ id, files }) => {
    setSelectedFiles((prevValue) => {
      const newValue = {
        ...prevValue,
        [id]: files,
      };
      if (Object.keys(newValue).length > 0) {
        let allFiles = [];
        Object.keys(newValue).forEach((d) => {
          allFiles = allFiles.concat(newValue[d]);
        });
        dispatch(
          setCurrentSelectedDataSources({
            files: allFiles,
          })
        );
      }
      return newValue;
    });
  };
  const getDatasetOptionTemplate = (itemData, itemIndex, itemElement) => {
    const count = getNumberOfSelect(itemData);
    return (
      <div className="d-flex">
        <div className="ml-1 mr-2">{itemData.name}</div>
        {count > 0 && (
          <Badge bg="primary" className=" text-white align-items-center d-flex">
            <span className="mr-1">{getNumberOfSelect(itemData)} </span>
            <span> selected. </span>
          </Badge>
        )}
      </div>
    );
  };

  const filteredAvatars = membersData?.members.filter(avatar =>
    avatar.displayName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelect = (avatar) => {
    //@ts-ignore
    const quill = quillRef.current.getEditor();

    if (!quill.hasFocus()) {
      quill.focus();
    }
    const cursorPosition = quill.getSelection().index;
    const mentionText = `@${avatar.name} `;
    const textBeforeCursor = quill.getText(0, cursorPosition);
    const lastAtSymbol = textBeforeCursor.lastIndexOf("@");
    quill.deleteText(lastAtSymbol, cursorPosition - lastAtSymbol);
    quill.clipboard.dangerouslyPasteHTML(lastAtSymbol, mentionText);
    quill.setSelection(lastAtSymbol + mentionText.length);
    setShowDropdown(false);
    setCollaborate(quill.root.innerHTML);
  };

  const onValueChanged = (content, delta, source, editor) => {
    setCollaborate(content);
    const cursorPosition = editor.getSelection().index;
    const textBeforeCursor = editor.getText(0, cursorPosition);
    const lastAtSymbol = textBeforeCursor.lastIndexOf("@");

    if (lastAtSymbol !== -1 && lastAtSymbol < cursorPosition) {
      setShowDropdown(true);
      setSearchQuery(textBeforeCursor.slice(lastAtSymbol + 1));
    } else {
      setShowDropdown(false);
    } if (lastAtSymbol !== -1 && lastAtSymbol < cursorPosition) {
      setShowDropdown(true);
      setSearchQuery(textBeforeCursor.slice(lastAtSymbol + 1));
    } else {
      setShowDropdown(false);
    }
  };

  return (
    <Wrapper key={1} className="d-flex justify-content-center">
      <div className="content" style={{margin: '50px'}}>
        <form>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <h4 className="mr-3">Requirements &amp; Responses</h4>

              <div className="d-flex align-items-center">
                {!isLoading && (
                  <Button
                    className="button"
                    text="Generate Document"
                    type="normal"
                    stylingMode="outlined"
                    onClick={submitProposal}
                  />
                )}
                {!isFinalizeProcessing && !isLoading && (docxUrl || data?.docx_url) ? (
                  <LinkButton onClick={downloadFile} data-url={(docxUrl || data?.docx_url)}>
                    <ActionIconButton
                      icon={faDownload}
                    />
                    <span>(The link will expire in 1 hour. If access is denied, click "Generate Document" again to generate a new download.)</span>
                  </LinkButton>
                ) : (isFinalizeProcessing && <LoadIndicator className="text-white" />)}
              </div>
            </div>
            <div className="justify-content-end flex-column d-flex m-3">
              <Badge bg="primary" className="ml-2 mb-2 text-white">
                <span>{currentSelectedSource.files.length} </span>
                <span> selected.</span>
              </Badge>
              {
                <div
                  onClick={toggleFilteringModal}
                  className="ml-2 rounded border p-1 align-items-center d-flex"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                >
                  <ActionIconButton
                    icon={faPlus}
                    style={{ fontSize: "14px" }}
                  />
                  <span className="ml-2">Add Source</span>
                </div>
              }
            </div>
          </div>
          {isLoading && <LoadIndicator />}
          {!isLoading && (
            <List
              dataSource={getSummary()}
              height="calc( 100% - 300px )"
              itemRender={renderSummary}
            />
          )}
        </form>
      </div>
      {getSummary().length > 0 &&
        (currentRequirementId || currentResponseId) && (
          <RightFixedPanel>
            <div>
              <div>
                {
                  <MessageBoard className="card">
                    <div className="card-header">
                      <h5>Collaborate on {currentResponseId ? `Response` : `Requirement`} {currentRequirementId}</h5>
                      {/* {currentRequirementIdx > 1 && (
                        <ActionIconButton
                          icon={faCircleLeft}
                          onClick={() => {
                            setCurrentRequirementIdx(currentRequirementIdx - 1);
                          }}
                        />
                      )}
                      <div className="flex-grow-1 justify-content-center d-flex">
                        {getMessageBoardTitle()}
                      </div>
                      {currentRequirementIdx < getSummary().length && (
                        <ActionIconButton
                          icon={faCircleRight}
                          onClick={() => {
                            setCurrentRequirementIdx(currentRequirementIdx + 1);
                          }}
                        />
                      )} */}
                    </div>
                    <div className="card-body d-flex p-0">
                      <div className="h-100 overflow-auto">
                        <div className="h-100 overflow-auto mt-3 mb-3">
                          {cardContent()}
                        </div>
                      </div>
                    </div>
                    <div className="card-text border-top pt-3">
                      {showDropdown && (
                        <MentionDropdownInverse>
                          {filteredAvatars && ([
                            { id: 'unique-id-kbrain', displayName: "KBRaiN", photoUrl: null },
                            ...membersData.members.filter((avatar) => avatar.displayName !== "KBRaiN")
                          ].map((avatar) => (
                            <div
                              key={avatar.id}
                              onClick={() => handleSelect(avatar)}
                              style={{ cursor: "pointer" }}
                              className="d-flex align-items-center p-3"
                            >
                              {avatar.displayName === "KBRaiN" ? (
                                <Logo />
                              ) : (
                                <Avatar email={avatar.photoUrl} />
                              )}
                              <span className="ml-1">{avatar.displayName !== null ? avatar.displayName : "Unknown"}</span>
                            </div>
                          )))}
                        </MentionDropdownInverse>
                      )}
                      <InputWrapper
                        className="d-flex align-items-center pt-3"
                        hide={true}
                        minW={true}
                      >
                        <ReactQuill
                          //@ts-ignore
                          ref={quillRef}
                          modules={modules}
                          formats={[]}
                          value={collaborate}
                          theme="snow"
                          onChange={onValueChanged}
                          onKeyDown={onKeydown}
                          placeholder="Replay..."
                          className="inputbox flex-grow-1"
                        />
                        <PlainButton onClick={handleSendingMessage}>
                          <FontAwesomeIcon
                            icon={faPaperPlane}
                            className={`cursor-pointer`}
                          />
                        </PlainButton>
                      </InputWrapper>
                    </div>
                  </MessageBoard>
                }
              </div>
            </div>
          </RightFixedPanel>
        )}
      <Popup
        width={560}
        height={520}
        visible={showFilteringModal}
        onHiding={hideFilteringModal}
        hideOnOutsideClick={true}
        showCloseButton={true}
        title="Select Files"
      >
        <div className="popup-content h-100">
          {/* <div className="steps-bar m-3 flex flex-row gap-2 h-1 mb-3">
            <div className="rounded-bar flex-1 rounded-sm bg-blue-600 bg-gray-300`"></div>
            <div className="rounded-bar flex-1 rounded-sm bg-blue-600 bg-gray-300`"></div>
            <div className="rounded-bar flex-1 rounded-sm bg-blue-600 bg-gray-300`"></div>
          </div> */}
          <div className="content p-3 h-100">
            {datasetsLoading && (
              <ContentLoaderWrapper className="d-flex justify-content-center align-items-center h-100">
                <LoadIndicator />
                <p className="m-0 ml-2">loading datasets...</p>
              </ContentLoaderWrapper>
            )}

            {!datasetsLoading && (
              <div className="d-flex flex-column">
                <label>Dataset: </label>
                <SelectBox
                  items={sortDatasetList}
                  className={"ml-2 mb-2"}
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={onDatasetSelectionChanged}
                  value={currentSelectedDataset}
                  itemRender={getDatasetOptionTemplate}
                />
              </div>
            )}
            {currentSelectedSource.datasets
              ?.filter((d) => {
                return d.id == currentSelectedDataset;
              })
              .map((dataset, index) => {
                return (
                  <div key={index} className={"mb-3"}>
                    {isLoadingDatasetFiles && (
                      <Badge bg="primary" className="mb-2 text-white">
                        {dataset.name}
                      </Badge>
                    )}
                    {isLoadingDatasetFiles && (
                      <ContentLoaderWrapper>
                        <LoadIndicator />
                      </ContentLoaderWrapper>
                    )}
                    {!isLoadingDatasetFiles && dataset.files && (
                      <DataGrid
                        ref={(el) => (myRefs.current[index] = el)}
                        id={dataset.id}
                        dataSource={dataset.files}
                        showColumnHeaders={false}
                        keyExpr="id"
                        showBorders={false}
                        showColumnLines={false}
                        allowColumnReordering={true}
                        columnAutoWidth={false}
                        allowColumnResizing={true}
                        // onRowPrepared={dataEntryRow}
                        width={"100%"}
                        height={"300"}
                        onSelectionChanged={onDatasetFilesSelectionChanged}
                        noDataText="No Files"
                        onContentReady={setValues}
                        onCellClick={onFileDataGridCellClick}
                      >
                        <Toolbar>
                          <Item location="before">
                            <div className="informer d-flex align-items-center mb-2">
                              <Badge bg="primary" className=" text-white">
                                {dataset.name}
                              </Badge>
                              <div className="ml-1 mr-2">
                                <span>{getNumberOfSelect(dataset)} </span>
                                <span> selected. </span>
                              </div>
                            </div>
                          </Item>
                          <Item name="searchPanel" />
                        </Toolbar>
                        <Scrolling mode={"virtual"} />
                        <SearchPanel visible={true} width={100} />
                        <Selection
                          mode="multiple"
                          showCheckBoxesMode={"always"}
                        />
                        <Column dataField="filename" caption="File Name" />
                        <Column
                          dataField="url"
                          width="100px"
                          caption="File Name"
                          cellRender={(data) => {
                            return (
                              <div className="d-flex align-items-center">
                                <div
                                  data-url={data.data.url}
                                  onClick={openFile}
                                >
                                  <ActionIconButton
                                    icon={faArrowUpRightFromSquare}
                                    className="ml-3"
                                  />
                                </div>
                                <div
                                  onClick={() => onFileDetailClick(data.data)}
                                >
                                  <ActionIconButton
                                    icon={faList}
                                    className="ml-3"
                                  />
                                </div>
                              </div>
                            );
                          }}
                        />
                      </DataGrid>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        {/* <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          disabled={
            currentSelectedDataset ? currentSelectedDataset.length == 0 : true
          }
          options={datasetModalButtonOptions}
        /> */}
      </Popup>
    </Wrapper>
  );
};

export default RequirementReview;

export const createDerivedFromId = (derivedFrom: Dict, requirement_index: string) => {
  return (derivedFrom.pdfName.replace(/[^a-z0-9-]/gi, "") +
    derivedFrom.page +
    derivedFrom.paragraph +
    requirement_index).replace(/^[0-9]+/, "");
}