import { useState, useEffect } from "react";
import {
  ReleaseButton,
  ReleaseInfoIcon,
} from "@app/components/chat-introduction/styles";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
const ReleaseContent = () => {
  const VIEWED_RELEASE_VERSIONS_STORAGE_KEY = `release-note-viewed-versions`;
  const latestVersion = "beta.1.2.0";
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const value =
      localStorage.getItem(VIEWED_RELEASE_VERSIONS_STORAGE_KEY) || "[]";
    const viewedVersion = JSON.parse(value);
    if (
      (Array.isArray(viewedVersion) &&
        !viewedVersion.includes(latestVersion)) ||
      !Array.isArray(viewedVersion)
    ) {
      setShowPopup(true);
    }
  }, []);
  useEffect(() => {
    if (showPopup === true) {
      const value =
        localStorage.getItem(VIEWED_RELEASE_VERSIONS_STORAGE_KEY) || "[]";
      const viewedVersion = JSON.parse(value);
      const allVersions = [...viewedVersion, latestVersion];
      //@ts-ignore
      const uniqViewVerions = [...new Set(allVersions)];
      localStorage.setItem(
        VIEWED_RELEASE_VERSIONS_STORAGE_KEY,
        JSON.stringify(uniqViewVerions)
      );
    }
  }, [showPopup]);

  const hideInfo = () => {
    setShowPopup(false);
  };
  const closeButtonOptions = {
    text: "Close",
    onClick: hideInfo,
  };
  return (
    <div>
      <Popup
        visible={showPopup}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        title="Release Notes"
        width={500}
        height={380}
        container=".main-content"
      >
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={closeButtonOptions}
        />
        <p className="overflow-auto h-100 m-3">
          Jan. 2, 2024: Enhanced KBRaiN with links to the <a href="https://kbrcorp.sharepoint.com/sites/KBRaiN" title="kbrain platform">KBRaiN</a> platform, tools and resources, and more.
          <p className="font-weight-bold mt-3 mb-2">
            What does it mean to you?
          </p>
          To enter the KBRaiN platform, go to Access <a href="/kchat" title='kchat'>KBRaiN™</a>
        </p>
      </Popup>
      <div className="d-flex justify-content-end">
        <ReleaseButton
          className="badge badge-light border-0 mt-3 mr-2"
          onClick={() => setShowPopup(!showPopup)}
        >
          <span>Release Notes</span>
          <ReleaseInfoIcon icon={faInfo} className="ml-1" />
        </ReleaseButton>
      </div>
    </div>
  );
};

export default ReleaseContent;
