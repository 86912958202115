export const ArticleIcon = (props) => {
  return (
    <div {...props}>
      <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.174 12.7826H9.91313V11.8261H4.174V12.7826ZM4.174 8.95652H12.7827V7.99999H4.174V8.95652ZM4.174 5.13043H12.7827V4.17391H4.174V5.13043ZM2.37131 16.1304C1.93107 16.1304 1.56349 15.983 1.26856 15.688C0.973636 15.3931 0.826172 15.0255 0.826172 14.5853V2.37122C0.826172 1.93098 0.973636 1.5634 1.26856 1.26847C1.56349 0.973544 1.93107 0.82608 2.37131 0.82608H14.5854C15.0256 0.82608 15.3932 0.973544 15.6881 1.26847C15.9831 1.5634 16.1305 1.93098 16.1305 2.37122V14.5853C16.1305 15.0255 15.9831 15.3931 15.6881 15.688C15.3932 15.983 15.0256 16.1304 14.5854 16.1304H2.37131ZM2.37131 15.1739H14.5854C14.7325 15.1739 14.8674 15.1126 14.9901 14.99C15.1127 14.8673 15.174 14.7324 15.174 14.5853V2.37122C15.174 2.22408 15.1127 2.08918 14.9901 1.96654C14.8674 1.84392 14.7325 1.7826 14.5854 1.7826H2.37131C2.22417 1.7826 2.08927 1.84392 1.96663 1.96654C1.84401 2.08918 1.78269 2.22408 1.78269 2.37122V14.5853C1.78269 14.7324 1.84401 14.8673 1.96663 14.99C2.08927 15.1126 2.22417 15.1739 2.37131 15.1739Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
