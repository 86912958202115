import { MESSAGE_ROLE } from "@app/api/types";
import { useAppContext } from "@app/app/appContext";
import AvatarAssisant from "@app/components/avatar/assistant";
import {
  ActionIconButton, ChatContentWrapper,
  ChatMessageWrapper
} from "@app/components/chat-content/styles";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import Avatar from "../avatar/user";

const NewChatContent = () => {
  const chat = useAppContext();
  const {
    isCurrentChatQueryProcessing,
    processingMessage,
    auth: { user },
  } = chat;

  const chatError = chat.currentChat?.error;

  return (
    <ChatContentWrapper>
      <ChatMessageWrapper from={MESSAGE_ROLE.ASSISTANT}>
        {processingMessage && (
          <div className="d-flex">
            <div>
              <Avatar email={user?.username} />
            </div>
            <div className="flex-grow-1"><div dangerouslySetInnerHTML={{ __html: processingMessage}} /></div>
            <div></div>
          </div>
        )}
        {!isCurrentChatQueryProcessing && !chatError && (
          <div className="d-flex">
            <div>
              <AvatarAssisant />
            </div>
            <div className="flex-grow-1">
              <div>
              Hello, my name is KBRaiN&#8480;! I am your virtual assistant. I'm only a bot (not a human), but I'll do my best to help! Please ask me a question by typing in the field below and clicking the send icon, or pressing enter.
              </div>
            </div>
            <div></div>
          </div>
        )}
      </ChatMessageWrapper>
      {isCurrentChatQueryProcessing && !chatError && (
        <ChatMessageWrapper from="assistant">
          <div className="d-flex">
            <div>
              <AvatarAssisant />
            </div>
            <div className="flex-grow-1">
              <ActionIconButton icon={faComment} className="mt-1 blink" />
            </div>
            <div>
              <span>Now</span>
            </div>
          </div>
        </ChatMessageWrapper>
      )}
      {chatError && !isCurrentChatQueryProcessing && (
        <ChatMessageWrapper from="assistant">
          <div className="d-flex">
            <div>
              <AvatarAssisant />
            </div>
            <div className="flex-grow-1 text-danger">{chatError}</div>
            <div>
              <span>Now</span>
            </div>
          </div>
        </ChatMessageWrapper>
      )}
    </ChatContentWrapper>
  );
};
export default NewChatContent;
