export const currentTimetzone =
  Intl.DateTimeFormat().resolvedOptions().timeZone;
export const defaultFormat = "MM/DD/YYYY, HH:mm A";
export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getAuthFromStorage = () => {
  const accesstoken = window.localStorage.getItem("token");
  const accountInfo = window.localStorage.getItem("user");
  return [accountInfo, accesstoken];
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const scrollToBottom = (ref: any, params?: any) => {
  //@ts-ignore
  ref.current?.scrollIntoView(params);
};
