import { AuthenticationResult } from "@azure/msal-browser";

export const refreshToken = async (instance, scopes) => {
  const loginRequest = { scopes };
  let result: AuthenticationResult;
  const userAccount = instance.getActiveAccount();

  if (userAccount === null) {
    result = await instance.loginPopup(loginRequest);
    instance.setActiveAccount(result.account);
  } else {
    result = await instance.acquireTokenSilent({
        ...loginRequest,
        account: userAccount,
    });
  }
  return result.accessToken
};
