import { Button, ButtonGroup } from "devextreme-react";
import { styled } from "styled-components";
export const COLOR = {
  WHITE: "#fff",
  LIGHT_GRAY: "#F5F5F5",
  LIGHTER_GRAY: "#888E93",
  CORN_FLOWER_BLUE: "#173753",
  CORN_FLOWER_BLUE_LIGHTER: "#2d4559",
  ICON_BG: "#004987",
  LIGHT_BLUE: "#4588EC",
  BORDER_GRAY: "#4a5259",
};

export const ContentWrapper = styled.div<{paddingBottom?: string, gap?: string}>`
  background-color: ${COLOR.LIGHT_GRAY};
  gap: ${({gap}) => gap+'px' || '30px'};
  .dx-datagrid .dx-row-alt {
    background-color: #ff0000;
  }
  padding-bottom: ${({paddingBottom}) => paddingBottom || '0'}
`;
export const ContentLoaderWrapper = styled.div<{ size?: string }>`
  width: ${({ size }) => size + "px" || "32px"};
  height: ${({ size }) => size + "px" || "32px"};
  margin: auto;
`;

export const PlainButton = styled.button`
  background: none;
  border: none;
  &:focus {
    outline: 0;
  }
`;


export const GrayPage = styled.div`
  background-color: ${COLOR.LIGHT_GRAY};
  height: 100%;
  overflow-y: auto;
`
export const FixWidthContentWrapper = styled.div<{paddingBottom?: string, gap?: string}>`
  background-color: ${COLOR.LIGHT_GRAY};
  width: 1200px;
  margin: auto;

  @media (max-width: 1200px) {
    width: auto; 
    max-width: 100%; 
  }
  gap: ${({gap}) => gap+'px' || '30px'};
  .dx-datagrid .dx-row-alt {
    background-color: #ff0000;
  }
  padding-bottom: ${({paddingBottom}) => paddingBottom || '0'}
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${COLOR.WHITE};
  gap: 20px;
  padding: 20px 20px 20px 20px;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
`;

export const ResponsiveDiv = styled.div`
  margin-bottom: 1rem;
  margin-right: 1rem;
  width: 250px;

  @media (max-width: 932px) {
    width: 200px;
  }
  @media (max-width: 560px) {
    width: 280px;
  }`
  ;

export const CardHalf = styled.div`
  width: 100vw;
  height: 35vh;
  border: 2px solid #e6b712;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: .5rem;
`;
export const CardHeader = styled.div`
  width: 100%;
  padding: 2px;
  padding-left: 30px;
  background-color: ${COLOR.WHITE};
  border-bottom: 1px solid #e6b712;
  text-align: start;
  font-weight: bold;
  font-size: 20px;
`;
export const CardContent = styled.div`
  padding-top: 15px;
  padding-left: 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
.carousel-control-next, .carousel-control-prev {
  color: transparent;
}
.carousel-control-prev {
  left: -10px;
}
.carousel-control-next {
  right: -40px; 
}

`;
export const CustomButton = styled(Button)`
  background-color: #e6b712 !important; 
  font-weight: bold;
  font-size: 15px;
  padding: 4px;
  color: #00205c !important;
  border: none; 
  border-radius: 30px !important; 
  padding: 5px 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); 
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    color: white !important;
    background-color: #00205c  !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); 
  }

  svg {
    color: #00205c;
    margin-left: 8px;
    transition: all 0.3s ease; 
    transform: rotate(180deg);
  }

  &:hover svg {
    color: white;
    transform: rotate(180deg);
  }
  .plus{
    font-size: 15px;
  }

  @media (max-width: 630px) {
    font-size: 12px;
    padding: 2px 5px;
    .plus{
      font-size: 12px;
      padding: 0px;
    }
  }
`;

export const StyledInput = styled.input`
  height: 40px;
  max-width: 300px;
  font-size: 15px;
  padding: 0 10px; 
  border-radius: 4px;
  border: 1px solid #ced4da; 
  width: 100%;
  margin-right: 1.25rem;

  @media (max-width: 770px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 630px) {
    font-size: 12px;
    height: 30px;
    width: 130px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

export const CustomToggle = styled(ButtonGroup)`
.dx-button-mode-outlined.dx-button-success .dx-icon {
  color: #00205c !important;
}

.dx-button-has-text .dx-button-content {
  padding: 7px 18px 8px;
  @media (max-width: 630px) {
    padding: 5px 10px 6px;
  }
}

.dx-button {
  background-color: #e6b712 !important; 
  font-weight: bold;
  font-size: 15px;
  color: #00205c !important;
  border: none; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); 
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    color: white !important;
    background-color: #00205c  !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
    .dx-icon {
      color: white !important;
    }
  }

  svg {
    color: #00205c;
    margin-left: 8px;
    transition: all 0.3s ease; 
    transform: rotate(180deg);
  }
      &:hover svg {
    color: #00205c;
    transform: rotate(180deg);
  }
  @media (max-width: 630px) {
    font-size: 12px;
    .dx-icon {
      font-size: 12px;
    }
  }
`;
export const HoverButton = styled.button`
  color: #00205c;
  background-color: #e6b712; 
  border: none; 
  border-radius: 30px; 
  padding: 4px 10px;
  font-size: 11px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); 
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    color: white;
    background-color: #00205c ;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); 
  }

  svg {
    color: #00205c;
    margin-left: 8px;
    transition: all 0.3s ease; 
    transform: rotate(180deg);
  }

  &:hover svg {
    color: white;
    transform: rotate(180deg);
  }
`;
