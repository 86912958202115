import React from "react";
import styled from "styled-components";
import logo from "@app/styles/assets/kbrain_logo.png";

export const LogoImg = styled.img`
  width: 25px;
  height: 23px;
`;

export const LogoText = styled.span`
  font-family: Lato;
  font-size: 19px;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
`;
export const Logo = (props) => {
  return <LogoImg src={logo} {...props} />;
};
