
export const ENDPOINT_LIST_CHAT = "chatlist";
export const ENDPOINT_VIEW_CHAT = "view_chat";
export const ENDPOINT_INITIALIZE_CHAT = "start_chat";
export const ENDPOINT_CONTINUE_CHAT = "continue_chat";
export const ENDPOINT_UPLOAD_FILES = "upload_file";
export const ENDPOINT_TRACK_FEEDBACK = "track_feedback";
export const ENDPOINT_GET_FEEDBACK = "get_feedback";
export const ENDPOINT_CHAT_STATS = "chat_stats";
export const ENDPOINT_LIST_SKILLS = "list_skills";
export const ENDPOINT_LIST_DATASETS = "list_datasets";
export const ENDPOINT_LIST_MODES = "list_modes";
export const ENDPOINT_LIST_FILES = "list_files"
export const ENDPOINT_LIST_CHAT_CONFIGURATION= "list_chat_configuration"
export const ENDPOINT_GET_CHAT_CONFIGURATION= "get_chat_configuration"
export const ENDPOINT_UPDATE_CHAT_CONFIGURATION= "update_chat_configuration"
export const CANCEL_REQUEST = "cancel_request";
