import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Toast } from "devextreme-react/toast";
import { EDIT_MODE, PROPOSAL_STATUS } from "@app/components/proposal/types";
import { useAppContext, useAppDispatchContext } from "@app/app/appContext";
import { setCurrentProposal } from "@app/app/actions";
import {
  TopNavigation,
  NavWrapper,
  ProgressBar,
  StatusIndicator,
  BackButton,
} from "@app/components/proposal/styles";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withSignalR from "@app/components/signalr/withSignalr";


const TopNavComponent = ({
  currentProposalId,
  currentProposalStatus,
  currentProposalError,
}) => {
  const [showToast, setShowToast] = useState(false);
  const dispatch = useAppDispatchContext();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { mode } = locationState || { mode: EDIT_MODE.EDIT };

  const goToStatus = (evt: React.MouseEvent<HTMLElement>, locationState = {}) => {
    dispatch(
      setCurrentProposal({
        id: currentProposalId,
        status: evt.currentTarget.dataset.status,
      })
    );
    navigate(`/proposal/${currentProposalId}/${evt.currentTarget.dataset.status}`, locationState);
  };

  const goHome = () => {
    navigate(`/proposal`);
  };

  const onHidingToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    if (currentProposalError) {
      setShowToast(true);
    }
  }, [currentProposalError]);

  return (
    <TopNavigation className="d-flex w-100">
      <BackButton
        className="d-flex align-items-center justify-content-center"
        onClick={goHome}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </BackButton>
      <NavWrapper className="d-flex flex-grow-1 align-items-center">
        <div className="flex-1 ml-3 ">&nbsp;</div>
        <ProgressBar className="d-flex flex-1">
          <StatusIndicator
            data-status={PROPOSAL_STATUS.HEADER_INFO}
            className="d-flex flex-column align-items-center"
            active={PROPOSAL_STATUS.HEADER_INFO === currentProposalStatus}
            onClick={(evt) => goToStatus(evt, { state: { mode: EDIT_MODE.EDIT } })}
          >
            <span className="circle">
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <label className="m-0">Header Info</label>
          </StatusIndicator>
          {mode === EDIT_MODE.EDIT && (
            <>
              <StatusIndicator
                data-status={PROPOSAL_STATUS.DOCUMENT}
                className="d-flex flex-column align-items-center"
                active={PROPOSAL_STATUS.DOCUMENT === currentProposalStatus}
                onClick={goToStatus}
              >
                <span className="circle">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                <label className="m-0">Documents</label>
              </StatusIndicator>
              <StatusIndicator
                data-status={PROPOSAL_STATUS.WIN_THEME_REVIEW}
                className="d-flex flex-column align-items-center"
                active={PROPOSAL_STATUS.WIN_THEME_REVIEW === currentProposalStatus}
                onClick={goToStatus}
              >
                <span className="circle">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                <label className="m-0">Win Theme</label>
              </StatusIndicator>
              <StatusIndicator
                data-status={PROPOSAL_STATUS.REQUIREMENT_REVIEW}
                className="d-flex flex-column align-items-center"
                active={
                  PROPOSAL_STATUS.REQUIREMENT_REVIEW === currentProposalStatus
                }
                onClick={goToStatus}
              >
                <span className="circle">
                  <FontAwesomeIcon icon={faCheck} />
                </span>
                <label className="m-0">Requirements &amp; Responses</label>
              </StatusIndicator>
            </>
        )}
        </ProgressBar>
        <div className="d-flex flex-1 justify-content-end pr-3">&nbsp;</div>
      </NavWrapper>
      <Toast
        visible={showToast}
        message={currentProposalError}
        type={"error"}
        onHiding={onHidingToast}
        displayTime={2000}
      />
    </TopNavigation>
  );
};

const TopNav = () => {
  const state = useAppContext();
  const {
    currentProposal: {
      id: currentProposalId,
      status: currentProposalStatus,
      error: currentProposalError,
      //@ts-ignore
      signalR,
    },
  } = state;
  const { state: locationState } = useLocation();
  const { mode } = locationState || { mode: EDIT_MODE.EDIT };

  const WrappedTopNavComponent = withSignalR(TopNavComponent, {
    groupName: currentProposalId,
    mode: mode
  });
  return (
    <WrappedTopNavComponent
      currentProposalId={currentProposalId}
      currentProposalStatus={currentProposalStatus}
      currentProposalError={currentProposalError}
    /> 
  );
};

export default TopNav;
