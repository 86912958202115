import { useEffect } from "react";
import { ContentWrapper } from "@app/styles";
import { useLocation } from "react-router-dom";
import { ERROR } from "@app/app/types";
import { ChatContentWrapper } from "@app/components/chat-content/styles";
import { ActionIconButton } from "@app/components/chat-content/styles";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { SP_LADNDING } from "@app/api/types";

const Home = () => {
  const { hash } = useLocation();
  const parsedHash = new URLSearchParams(
    hash.substring(1) // any_hash_key=any_value
  );
  const hasError = parsedHash && parsedHash.get("error");

  useEffect(() => {
    if (!hasError) {
      // navigate("/kchat");
      window.location.href = SP_LADNDING
    }
  }, []);

  return (
    <div className="d-flex h-100 bg-white">
      <ContentWrapper className="d-flex flex-column bg-white justify-content-betweend-flex flex-column justify-content-between flex-grow-1 h-100 overflow-auto">
        {parsedHash && parsedHash.get("error") == ERROR.ACCESS_DENIED && (
          <ChatContentWrapper>
            <div className="card m-3">
              <div className="card-body">
                <div className="d-flex ">
                  <ActionIconButton
                    icon={faCircleExclamation}
                    className="mr-3"
                  />
                  <div>Access Denied</div>
                </div>
              </div>
            </div>
          </ChatContentWrapper>
        )}
      </ContentWrapper>
    </div>
  );
};
export default Home;
